import React, { useRef, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  CheckCircleFill,
  ChevronRight,
  CircleFill,
  DashCircleFill,
  XLg,
} from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import PendingCircleFill from "../../../Assets/Images/Icons/pending-circle-fill.svg";
import "./styles.scss";
import SubscriptionDetails from "../../Popup/SubscriptionDetail";

const SubscriptionTable = ({
  ColumnNameMicrosoftCSP,
  showPriceFlag,
  showRRPFlag,
  updatedQuantityIntermediate,
  isSubscriptionDetailsPopupOpen,
  setIsSubscriptionDetailsPopupOpen,
  integrationCode,
  subscriptionDetailIsLoadingFlag,
  subscriptionDetailsIsTrial,
  subscriptionDetailsPlanName,
  subscriptionDetailsVendorSubscriptionId,
  subscriptionDetailsLicenceAutoRenewal,
  subscriptionDetailsStartDate,
  subscriptionDetailsEndDate,
  expendableRowOpen,
  setExpendableRowOpen,
  subscriptionId,
  updatedQuantityIntermediateErrorState,
  setUpdatedQuantityIntermediateErrorState,
  addOnsPlanArray,
  subscriptionDetailsTrialEndDate,
  subscriptionTable,
  selectedOrderSummaryArray,
  setUpdatedQuantityIntermediate,
  setAddOnsSubscriptionId,
  setParentAddOns,
  setSubscriptionId,
  setIntermediateOrderSummaryArray,
  setShowPanel,
  GetSubscriptionDetails,
  AddOnPlanError,
  expandableRowLoading,
  parentAddOns,
  setActiveLocalKey,
  filterFlagLocalSubscriptionType,
  subscriptionTypeAllLocal,
  setLocalFilterOnChangeFlag,
  setSubscriptionTypeListLocal,
  subscriptionTypeFilter,
  setGlobalToLocalAllItemFlag,
  setSubscriptionTypeAllLocal,
  subscriptionTypeListLocal,
  filterFlagLocalBilling,
  statusAllLocal,
  billingTermAllLocal,
  setBillingTermListLocal,
  billingTermFilter,
  setBillingTermAllLocal,
  statusFilter,
  setStatusListLocal,
  billingTermListLocal,
  filterFlagLocalStatus,
  setStatusAllLocal,
  statusListLocal,
  setIsAllLocalChangeAPIFlag,
  SortStartDate,
  sortStartDateFlag,
}) => {
  const navigate = useNavigate();

  // Function to show overlay when hover on i icon in add member
  const renderTooltip = (props) => (
    <Tooltip {...props}>{props.infoText}</Tooltip>
  );
  const licenceQuantity = useRef();

  const [increaseQuantity, setIncreaseQuantity] = useState(true);
  const [decreaseQuantity, setDecreaseQuantity] = useState(true);

  const [isIncreaseAllowed, setIsIncreaseAllowed] = useState(true);
  const [isDecreaseAllowed, setIsDecreaseAllowed] = useState(true);

  return (

    <Table>
      <thead className="manage-subscription-csp-table-head sticky-top">
        <tr>
          {ColumnNameMicrosoftCSP.map((td) => {
            if (showPriceFlag === false && showRRPFlag === false) {
              if (
                td === "Plan" ||
                td === "Billing Term" ||
                td === "Subscription Type" ||
                td === "Start Date" ||
                td === " "
              ) {
                if (td === "Subscription Type") {
                  return (
                    <th className="manage-subscription-table-head-sub text-left">
                      <div>
                        <div>{td}</div>
                        <div>
                          <Dropdown className="d-none d-md-inline position-static">
                            {!filterFlagLocalSubscriptionType && (
                              <Dropdown.Toggle
                                variant="light"
                                id="dropdown-basic"
                                className="filter table-filter"
                              >
                                <span className="">&nbsp;</span>
                              </Dropdown.Toggle>
                            )}
                            {filterFlagLocalSubscriptionType && (
                              <Dropdown.Toggle
                                variant="light"
                                id="dropdown-basic"
                                className="filter-active table-filter"
                              >
                                <span className="">&nbsp;</span>
                              </Dropdown.Toggle>
                            )}

                            <Dropdown.Menu>
                              <Container>
                                <Form>
                                  <div
                                    key={`default-all-Type`}
                                    className="mb-3"
                                  >
                                    <Form.Check // prettier-ignore
                                      type="checkbox"
                                      id="AllType"
                                      label="All Type"
                                      checked={subscriptionTypeAllLocal}
                                      onClick={() => {
                                        setIsAllLocalChangeAPIFlag(true);
                                        setLocalFilterOnChangeFlag(true);
                                        setSubscriptionTypeListLocal({});
                                        // console.log(document.getElementById("NCE BASE").checked);
                                        subscriptionTypeFilter.map((item) => {
                                          setSubscriptionTypeListLocal(
                                            (prev) => ({
                                              subscriptionTypeListLocal: {
                                                ...prev.subscriptionTypeListLocal,
                                                [item]:
                                                  !subscriptionTypeAllLocal,
                                              },
                                            })
                                          );
                                        });
                                        setGlobalToLocalAllItemFlag(false);
                                        setSubscriptionTypeAllLocal(
                                          !subscriptionTypeAllLocal
                                        );
                                      }}
                                    />
                                  </div>
                                  {subscriptionTypeFilter.map((type) => (
                                    <div
                                      key={`default-${type}`}
                                      className="mb-3"
                                    >
                                      <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id={type}
                                        label={type}
                                        checked={
                                          subscriptionTypeListLocal
                                            .subscriptionTypeListLocal[type]
                                        }
                                        onClick={() => {
                                          setLocalFilterOnChangeFlag(true);
                                          setSubscriptionTypeListLocal({});
                                          subscriptionTypeFilter.map((item) => {
                                            if (item == type) {
                                              // console.log(item,type,(document.getElementById(item)));
                                              setSubscriptionTypeListLocal(
                                                (prev) => ({
                                                  subscriptionTypeListLocal: {
                                                    ...prev.subscriptionTypeListLocal,
                                                    [item]:
                                                      document.getElementById(
                                                        item
                                                      ).checked,
                                                  },
                                                })
                                              );
                                              setGlobalToLocalAllItemFlag(
                                                false
                                              );
                                              setSubscriptionTypeAllLocal(
                                                false
                                              );
                                            } else {
                                              setSubscriptionTypeListLocal(
                                                (prev) => ({
                                                  subscriptionTypeListLocal: {
                                                    ...prev.subscriptionTypeListLocal,
                                                    [item]:
                                                      document.getElementById(
                                                        item
                                                      ).checked,
                                                  },
                                                })
                                              );
                                            }
                                          });
                                        }}
                                      />
                                    </div>
                                  ))}
                                </Form>
                              </Container>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </th>
                  );
                }
                if (td === "Billing Term") {
                  return (
                    <th className="manage-subscription-table-head-sub text-left">
                      <div>
                        {td}
                        <OverlayTrigger
                          placement="top"
                          overlay={renderTooltip({
                            infoText: "Billing Term (Commitment Term)",
                          })}
                        >
                          <span className="infoIcon-support-table ml-1"></span>
                        </OverlayTrigger>
                        <Dropdown className="d-none d-md-inline position-static">
                          {!filterFlagLocalBilling && (
                            <Dropdown.Toggle
                              variant="light"
                              id="dropdown-basic"
                              className="filter table-filter"
                            >
                              <span className="">&nbsp;</span>
                            </Dropdown.Toggle>
                          )}

                          {filterFlagLocalBilling && (
                            <Dropdown.Toggle
                              variant="light"
                              id="dropdown-basic"
                              className="filter-active table-filter"
                            >
                              <span className="">&nbsp;</span>
                            </Dropdown.Toggle>
                          )}

                          <Dropdown.Menu>
                            <Container>
                              <Form>
                                <div
                                  key={`default-all-Items`}
                                  className="mb-3"
                                >
                                  <Form.Check // prettier-ignore
                                    type="checkbox"
                                    id="AllItems"
                                    label="All Items"
                                    checked={billingTermAllLocal}
                                    onClick={() => {
                                      setIsAllLocalChangeAPIFlag(true);
                                      setLocalFilterOnChangeFlag(true);
                                      setBillingTermListLocal({});
                                      // console.log(document.getElementById("NCE BASE").checked);
                                      billingTermFilter.map((item) => {
                                        setBillingTermListLocal((prev) => ({
                                          billingTermListLocal: {
                                            ...prev.billingTermListLocal,
                                            [item]: !billingTermAllLocal,
                                          },
                                        }));
                                      });
                                      setGlobalToLocalAllItemFlag(false);
                                      setBillingTermAllLocal(
                                        !billingTermAllLocal
                                      );
                                    }}
                                  />
                                </div>
                                {billingTermFilter.map((type) => (
                                  <div
                                    key={`default-${type}`}
                                    className="mb-3"
                                  >
                                    <Form.Check // prettier-ignore
                                      type="checkbox"
                                      id={type}
                                      label={type}
                                      checked={
                                        billingTermListLocal
                                          .billingTermListLocal[type]
                                      }
                                      onClick={() => {
                                        setLocalFilterOnChangeFlag(true);
                                        setBillingTermListLocal({});
                                        billingTermFilter.map((item) => {
                                          if (item == type) {
                                            // console.log(item,type,(document.getElementById(item)));
                                            setBillingTermListLocal(
                                              (prev) => ({
                                                billingTermListLocal: {
                                                  ...prev.billingTermListLocal,
                                                  [item]:
                                                    document.getElementById(
                                                      item
                                                    ).checked,
                                                },
                                              })
                                            );
                                            setGlobalToLocalAllItemFlag(
                                              false
                                            );
                                            setBillingTermAllLocal(false);
                                          } else {
                                            setBillingTermListLocal(
                                              (prev) => ({
                                                billingTermListLocal: {
                                                  ...prev.billingTermListLocal,
                                                  [item]:
                                                    document.getElementById(
                                                      item
                                                    ).checked,
                                                },
                                              })
                                            );
                                          }
                                        });
                                      }}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </Container>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </th>
                  );
                }
                if (td === "Start Date") {
                  return (
                    <th className="manage-subscription-table-head-sub text-left">
                      {sortStartDateFlag === "DESC" && <div>{td}<span className="sort-icon-asc mx-1" onClick={() => { SortStartDate("ASC") }}></span></div>}
                      {sortStartDateFlag === "ASC" && <div>{td}<span className="sort-icon-desc mx-1" onClick={() => { SortStartDate("DESC") }}></span></div>}
                    </th>
                  );
                }
                else {
                  return (
                    <th className="manage-subscription-table-head-sub text-left">
                      <div>{td}</div>
                    </th>
                  );
                }
              }
              if (
                td === "Status" ||
                td === "Support Tickets" ||
                td === "Info"
              ) {
                if (td === "Status") {
                  return (
                    <th className="manage-subscription-table-head-sub text-center">
                      <div>
                        <div className="col-12">
                          {td}
                          <Dropdown className="d-none d-md-inline position-static">
                            {!filterFlagLocalStatus && (
                              <Dropdown.Toggle
                                variant="light"
                                id="dropdown-basic"
                                className="filter table-filter"
                              >
                                <span className="">&nbsp;</span>
                              </Dropdown.Toggle>
                            )}
                            {filterFlagLocalStatus && (
                              <Dropdown.Toggle
                                variant="light"
                                id="dropdown-basic"
                                className="filter-active table-filter"
                              >
                                <span className="">&nbsp;</span>
                              </Dropdown.Toggle>
                            )}

                            <Dropdown.Menu>
                              <Container>
                                <Form>
                                  <div
                                    key={`default-all-Statuses`}
                                    className="mb-3"
                                  >
                                    <Form.Check // prettier-ignore
                                      type="checkbox"
                                      id="AllStatuses"
                                      label="All Statuses"
                                      checked={statusAllLocal}
                                      onClick={() => {
                                        setIsAllLocalChangeAPIFlag(true);
                                        setLocalFilterOnChangeFlag(true);
                                        setStatusListLocal({});
                                        // console.log(document.getElementById("NCE BASE").checked);
                                        statusFilter.map((item) => {
                                          setStatusListLocal((prev) => ({
                                            statusListLocal: {
                                              ...prev.statusListLocal,
                                              [item]: !statusAllLocal,
                                            },
                                          }));
                                        });
                                        setGlobalToLocalAllItemFlag(false);
                                        setStatusAllLocal(!statusAllLocal);
                                      }}
                                    />
                                  </div>
                                  {statusFilter.map((type) => (
                                    <div
                                      key={`default-${type}`}
                                      className="mb-3"
                                    >
                                      <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id={type}
                                        label={type}
                                        checked={
                                          statusListLocal.statusListLocal[type]
                                        }
                                        onClick={() => {
                                          setLocalFilterOnChangeFlag(true);
                                          setStatusListLocal({});
                                          statusFilter.map((item) => {
                                            if (item == type) {
                                              // console.log(item,type,(document.getElementById(item)));
                                              setStatusListLocal((prev) => ({
                                                statusListLocal: {
                                                  ...prev.statusListLocal,
                                                  [item]:
                                                    document.getElementById(
                                                      item
                                                    ).checked,
                                                },
                                              }));
                                              setGlobalToLocalAllItemFlag(
                                                false
                                              );
                                              setStatusAllLocal(false);
                                            } else {
                                              setStatusListLocal((prev) => ({
                                                statusListLocal: {
                                                  ...prev.statusListLocal,
                                                  [item]:
                                                    document.getElementById(
                                                      item
                                                    ).checked,
                                                },
                                              }));
                                            }
                                          });
                                        }}
                                      />
                                    </div>
                                  ))}
                                </Form>
                              </Container>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </th>
                  );
                } else {
                  return (
                    <th className="manage-subscription-table-head-sub text-center">
                      <div>
                        <div className="col-12">{td}</div>
                      </div>
                    </th>
                  );
                }
              }
              if (td === "Quantity") {
                return (
                  <th className="manage-subscription-table-head-sub text-right">
                    <div>
                      <div className="col-12">{td}</div>
                    </div>
                  </th>
                );
              }
            }
            if (showPriceFlag === true || showRRPFlag === true) {
              let priceColumnFlag="";
              if(showPriceFlag === true && showRRPFlag === true){
                priceColumnFlag="Show All"
              }
              if(showPriceFlag === true && showRRPFlag === false){
                priceColumnFlag="Show Price"
              }
              if(showPriceFlag === false && showRRPFlag === true){
                priceColumnFlag="Show RRP"
              }
              if (
                td === "Plan" ||
                td === "Billing Term" ||
                td === "Subscription Type" ||
                td === "Start Date" ||
                td === " "
              ) {
                if (td === "Subscription Type") {
                  return (
                    <th className="manage-subscription-table-head-sub text-left">
                      <div>
                        <div>{td}</div>
                        <div>
                          <Dropdown className="d-none d-md-inline position-static">
                            {!filterFlagLocalSubscriptionType && (
                              <Dropdown.Toggle
                                variant="light"
                                id="dropdown-basic"
                                className="filter table-filter"
                              >
                                <span className="">&nbsp;</span>
                              </Dropdown.Toggle>
                            )}
                            {filterFlagLocalSubscriptionType && (
                              <Dropdown.Toggle
                                variant="light"
                                id="dropdown-basic"
                                className="filter-active table-filter"
                              >
                                <span className="">&nbsp;</span>
                              </Dropdown.Toggle>
                            )}

                            <Dropdown.Menu>
                              <Container>
                                <Form>
                                  <div
                                    key={`default-all-Type`}
                                    className="mb-3"
                                  >
                                    <Form.Check // prettier-ignore
                                      type="checkbox"
                                      id="AllType"
                                      label="All Type"
                                      checked={subscriptionTypeAllLocal}
                                      onClick={() => {
                                        setIsAllLocalChangeAPIFlag(true);
                                        setLocalFilterOnChangeFlag(true);
                                        setSubscriptionTypeListLocal({});
                                        // console.log(document.getElementById("NCE BASE").checked);
                                        subscriptionTypeFilter.map((item) => {
                                          setSubscriptionTypeListLocal(
                                            (prev) => ({
                                              subscriptionTypeListLocal: {
                                                ...prev.subscriptionTypeListLocal,
                                                [item]:
                                                  !subscriptionTypeAllLocal,
                                              },
                                            })
                                          );
                                        });
                                        setGlobalToLocalAllItemFlag(false);
                                        setSubscriptionTypeAllLocal(
                                          !subscriptionTypeAllLocal
                                        );
                                      }}
                                    />
                                  </div>
                                  {subscriptionTypeFilter.map((type) => (
                                    <div
                                      key={`default-${type}`}
                                      className="mb-3"
                                    >
                                      <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id={type}
                                        label={type}
                                        checked={
                                          subscriptionTypeListLocal
                                            .subscriptionTypeListLocal[type]
                                        }
                                        onClick={() => {
                                          setLocalFilterOnChangeFlag(true);
                                          setSubscriptionTypeListLocal({});
                                          subscriptionTypeFilter.map((item) => {
                                            if (item == type) {
                                              // console.log(item,type,(document.getElementById(item)));
                                              setSubscriptionTypeListLocal(
                                                (prev) => ({
                                                  subscriptionTypeListLocal: {
                                                    ...prev.subscriptionTypeListLocal,
                                                    [item]:
                                                      document.getElementById(
                                                        item
                                                      ).checked,
                                                  },
                                                })
                                              );
                                              setGlobalToLocalAllItemFlag(
                                                false
                                              );
                                              setSubscriptionTypeAllLocal(
                                                false
                                              );
                                            } else {
                                              setSubscriptionTypeListLocal(
                                                (prev) => ({
                                                  subscriptionTypeListLocal: {
                                                    ...prev.subscriptionTypeListLocal,
                                                    [item]:
                                                      document.getElementById(
                                                        item
                                                      ).checked,
                                                  },
                                                })
                                              );
                                            }
                                          });
                                        }}
                                      />
                                    </div>
                                  ))}
                                </Form>
                              </Container>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </th>
                  );
                }
                if (td === "Billing Term") {
                  return (
                    <th className="manage-subscription-table-head-sub text-left">
                      <div>
                        {td}
                        <OverlayTrigger
                          placement="top"
                          overlay={renderTooltip({
                            infoText: "Billing Term (Commitment Term)",
                          })}
                        >
                          <span className="infoIcon-support-table ml-1"></span>
                        </OverlayTrigger>
                        <Dropdown className="d-none d-md-inline position-static">
                          {!filterFlagLocalBilling && (
                            <Dropdown.Toggle
                              variant="light"
                              id="dropdown-basic"
                              className="filter table-filter"
                            >
                              <span className="">&nbsp;</span>
                            </Dropdown.Toggle>
                          )}

                          {filterFlagLocalBilling && (
                            <Dropdown.Toggle
                              variant="light"
                              id="dropdown-basic"
                              className="filter-active table-filter"
                            >
                              <span className="">&nbsp;</span>
                            </Dropdown.Toggle>
                          )}

                          <Dropdown.Menu>
                            <Container>
                              <Form>
                                <div key={`default-all-Items`} className="mb-3">
                                  <Form.Check // prettier-ignore
                                    type="checkbox"
                                    id="AllItems"
                                    label="All Items"
                                    checked={billingTermAllLocal}
                                    onClick={() => {
                                      setIsAllLocalChangeAPIFlag(true);
                                      setLocalFilterOnChangeFlag(true);
                                      setBillingTermListLocal({});
                                      // console.log(document.getElementById("NCE BASE").checked);
                                      billingTermFilter.map((item) => {
                                        setBillingTermListLocal((prev) => ({
                                          billingTermListLocal: {
                                            ...prev.billingTermListLocal,
                                            [item]: !billingTermAllLocal,
                                          },
                                        }));
                                      });
                                      setGlobalToLocalAllItemFlag(false);
                                      setBillingTermAllLocal(
                                        !billingTermAllLocal
                                      );
                                    }}
                                  />
                                </div>
                                {billingTermFilter.map((type) => (
                                  <div key={`default-${type}`} className="mb-3">
                                    <Form.Check // prettier-ignore
                                      type="checkbox"
                                      id={type}
                                      label={type}
                                      checked={
                                        billingTermListLocal
                                          .billingTermListLocal[type]
                                      }
                                      onClick={() => {
                                        setLocalFilterOnChangeFlag(true);
                                        setBillingTermListLocal({});
                                        billingTermFilter.map((item) => {
                                          if (item == type) {
                                            // console.log(item,type,(document.getElementById(item)));
                                            setBillingTermListLocal((prev) => ({
                                              billingTermListLocal: {
                                                ...prev.billingTermListLocal,
                                                [item]:
                                                  document.getElementById(item)
                                                    .checked,
                                              },
                                            }));
                                            setGlobalToLocalAllItemFlag(false);
                                            setBillingTermAllLocal(false);
                                          } else {
                                            setBillingTermListLocal((prev) => ({
                                              billingTermListLocal: {
                                                ...prev.billingTermListLocal,
                                                [item]:
                                                  document.getElementById(item)
                                                    .checked,
                                              },
                                            }));
                                          }
                                        });
                                      }}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </Container>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </th>
                  );
                }
                if (td === "Start Date") {
                  return (
                    <th className="manage-subscription-table-head-sub text-left">
                      {sortStartDateFlag === "DESC" && <div>{td}<span className="sort-icon-asc mx-1" onClick={() => { SortStartDate("ASC") }}></span></div>}
                      {sortStartDateFlag === "ASC" && <div>{td}<span className="sort-icon-desc mx-1" onClick={() => { SortStartDate("DESC") }}></span></div>}
                    </th>
                  );
                }
                else {
                  return (
                    <th className="manage-subscription-table-head-sub text-left">
                      <div>{td}</div>
                    </th>
                  );
                }
              }
              if (
                td === "Status" ||
                td === "Support Tickets" ||
                td === "Info"
              ) {
                if (td === "Status") {
                  return (
                    <th className="manage-subscription-table-head-sub text-center">
                      <div>
                        <div className="col-12">
                          {td}
                          <Dropdown className="d-none d-md-inline position-static">
                            {!filterFlagLocalStatus && (
                              <Dropdown.Toggle
                                variant="light"
                                id="dropdown-basic"
                                className="filter table-filter"
                              >
                                <span className="">&nbsp;</span>
                              </Dropdown.Toggle>
                            )}
                            {filterFlagLocalStatus && (
                              <Dropdown.Toggle
                                variant="light"
                                id="dropdown-basic"
                                className="filter-active table-filter"
                              >
                                <span className="">&nbsp;</span>
                              </Dropdown.Toggle>
                            )}

                            <Dropdown.Menu>
                              <Container>
                                <Form>
                                  <div
                                    key={`default-all-Statuses`}
                                    className="mb-3"
                                  >
                                    <Form.Check // prettier-ignore
                                      type="checkbox"
                                      id="AllStatuses"
                                      label="All Statuses"
                                      checked={statusAllLocal}
                                      onClick={() => {
                                        setIsAllLocalChangeAPIFlag(true);
                                        setLocalFilterOnChangeFlag(true);
                                        setStatusListLocal({});
                                        // console.log(document.getElementById("NCE BASE").checked);
                                        statusFilter.map((item) => {
                                          setStatusListLocal((prev) => ({
                                            statusListLocal: {
                                              ...prev.statusListLocal,
                                              [item]: !statusAllLocal,
                                            },
                                          }));
                                        });
                                        setGlobalToLocalAllItemFlag(false);
                                        setStatusAllLocal(!statusAllLocal);
                                      }}
                                    />
                                  </div>
                                  {statusFilter.map((type) => (
                                    <div
                                      key={`default-${type}`}
                                      className="mb-3"
                                    >
                                      <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id={type}
                                        label={type}
                                        checked={
                                          statusListLocal.statusListLocal[type]
                                        }
                                        onClick={() => {
                                          setLocalFilterOnChangeFlag(true);
                                          setStatusListLocal({});
                                          statusFilter.map((item) => {
                                            if (item == type) {
                                              // console.log(item,type,(document.getElementById(item)));
                                              setStatusListLocal((prev) => ({
                                                statusListLocal: {
                                                  ...prev.statusListLocal,
                                                  [item]:
                                                    document.getElementById(
                                                      item
                                                    ).checked,
                                                },
                                              }));
                                              setGlobalToLocalAllItemFlag(
                                                false
                                              );
                                              setStatusAllLocal(false);
                                            } else {
                                              setStatusListLocal((prev) => ({
                                                statusListLocal: {
                                                  ...prev.statusListLocal,
                                                  [item]:
                                                    document.getElementById(
                                                      item
                                                    ).checked,
                                                },
                                              }));
                                            }
                                          });
                                        }}
                                      />
                                    </div>
                                  ))}
                                </Form>
                              </Container>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </th>
                  );
                } else {
                  return (
                    <th className="manage-subscription-table-head-sub text-center">
                      <div>
                        <div className="col-12">{td}</div>
                      </div>
                    </th>
                  );
                }
              }
              if(priceColumnFlag==="Show All"){
                if (td === "Quantity" || td === "Unit Price" || td === "RRP (Inc GST)") {
                  return (
                    <th className="manage-subscription-table-head-sub text-right">
                      <div>
                        <div className="col-12">{td}</div>
                      </div>
                    </th>
                  );
                }
              }
              if(priceColumnFlag==="Show Price"){
                if (td === "Quantity" || td === "Unit Price") {
                  return (
                    <th className="manage-subscription-table-head-sub text-right">
                      <div>
                        <div className="col-12">{td}</div>
                      </div>
                    </th>
                  );
                }
              }
              if(priceColumnFlag==="Show RRP"){
                if (td === "Quantity" || td === "RRP (Inc GST)") {
                  return (
                    <th className="manage-subscription-table-head-sub text-right">
                      <div>
                        <div className="col-12">{td}</div>
                      </div>
                    </th>
                  );
                }
              }
            }
          })}
        </tr>
      </thead>
      <tbody className="manage-subscription-csp-table-body">
        {/* Popup For Subscription Details */}
        <SubscriptionDetails
          isSubscriptionDetailsPopupOpen={isSubscriptionDetailsPopupOpen}
          setIsSubscriptionDetailsPopupOpen={setIsSubscriptionDetailsPopupOpen}
          subscriptionDetailsIsTrial={subscriptionDetailsIsTrial}
          subscriptionDetailIsLoadingFlag={subscriptionDetailIsLoadingFlag}
          subscriptionDetailsPlanName={subscriptionDetailsPlanName}
          subscriptionDetailsVendorSubscriptionId={
            subscriptionDetailsVendorSubscriptionId
          }
          subscriptionDetailsLicenceAutoRenewal={
            subscriptionDetailsLicenceAutoRenewal
          }
          subscriptionDetailsStartDate={subscriptionDetailsStartDate}
          subscriptionDetailsEndDate={subscriptionDetailsEndDate}
          subscriptionDetailsTrialEndDate={subscriptionDetailsTrialEndDate}
        />
        {subscriptionTable.map((td) => {
          return (
            <>
              <tr
                className={`manage-subscription-csp-table-row ${expendableRowOpen && td.subscriptionId === subscriptionId
                  ? "active"
                  : ""
                  } ${selectedOrderSummaryArray.includes(td.subscriptionId)
                    ? "selected"
                    : ""
                  }`}
              >
                <td
                  className="manage-subscription-csp-table-data text-right pr-0"
                  style={{
                    width: "20px !important",
                    paddingLeft: "0px !important",
                  }}
                >
                  {td.hasAddOnPlans === true && (
                    <button
                      className={`expand-row no-btn ${expendableRowOpen &&
                        td.subscriptionId === subscriptionId
                        ? "active"
                        : ""
                        }`}
                      onClick={(e) => {
                        setAddOnsSubscriptionId(td.subscriptionId);
                        setParentAddOns(td.planName);
                        // console.log(e.target.value);
                        setSubscriptionId(td.subscriptionId);
                        if (
                          td.subscriptionId == subscriptionId ||
                          subscriptionId === ""
                        ) {
                          setExpendableRowOpen(!expendableRowOpen);
                        }
                        if (td.subscriptionId != subscriptionId) {
                          setExpendableRowOpen(true);
                        }
                      }}
                    >
                      <ChevronRight size={14} color="#6A6A6A" />
                    </button>
                  )}
                </td>
                <td className="manage-subscription-csp-table-data pl-0">
                  {td.planName}
                </td>
                <td className="manage-subscription-csp-table-data text-right">
                  <label className="d-flex align-items-center justify-content-end">
                    {td.licenseCount}
                    {(td.subscriptionStatus !== "Active" ||
                      td.isTrialSub === true) && (
                        <span className="editpencil mx-3 mr-0 disabled cnotallowed">
                          &nbsp;
                        </span>
                      )}

                    <Popup
                      onOpen={() => {
                        td.licenseCount < td.maxQuantity ? setIsIncreaseAllowed(true) : setIsIncreaseAllowed(false);
                        td.licenseCount > td.minQuantity ? setIsDecreaseAllowed(true) : setIsDecreaseAllowed(false);
                      }
                      }
                      onClose={() => {
                        setUpdatedQuantityIntermediate(0);
                        setUpdatedQuantityIntermediateErrorState(false);
                        setIncreaseQuantity(true);
                        setDecreaseQuantity(true);
                        setIsIncreaseAllowed(true);
                        setIsDecreaseAllowed(true);
                      }}
                      trigger={
                        td.subscriptionStatus === "Active" &&
                        td.isTrialSub === false && (
                          <span className="editpencil mx-3 mr-0">&nbsp;</span>
                        )
                      }
                      className="custom-modal"
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <div className="header">
                            <h2>Edit Quantity</h2>
                            <XLg
                              size={24}
                              className="cpointer close-icon-popup"
                              onClick={close}
                            />
                          </div>
                          <Container>
                            <Row>
                              <Col xs={12}>
                                <p className="text-regular mb-4">
                                  <span className="text-strong ">Plan :</span>{" "}
                                  {td.planName}
                                </p>
                              </Col>
                              <Col md={4} className="col-6">
                                <p className="mb-0">Enter New Quantity</p>
                                {!updatedQuantityIntermediateErrorState && (
                                  <p className="text-small text-disabled">
                                    {"( Min " +
                                      td.minQuantity +
                                      ", Max " +
                                      td.maxQuantity +
                                      " )"}
                                  </p>
                                )}
                                {updatedQuantityIntermediateErrorState && (
                                  <p className="text-small text-danger">
                                    {"( Min " +
                                      td.minQuantity +
                                      ", Max " +
                                      td.maxQuantity +
                                      " )"}
                                  </p>
                                )}
                              </Col>
                              <Col md={4} className="col-6">
                                <div class="number-input">

                                  <Form.Control
                                    type="number"
                                    placeholder="Enter Quantity"
                                    defaultValue={td.licenseCount}
                                    min={td.minQuantity}
                                    max={td.maxQuantity}
                                    ref={licenceQuantity}
                                    disabled={!isIncreaseAllowed && !isDecreaseAllowed}
                                    onInput={(e) => {
                                      e.target.value =
                                        !!e.target.value && Math.abs(e.target.value) >= td.minQuantity ? Math.abs(e.target.value) : null;

                                      e.target.value =
                                        !!e.target.value && Math.abs(e.target.value) <= td.maxQuantity ? Math.abs(e.target.value) : null;

                                      if (!isIncreaseAllowed && e.target.value > td.licenseCount) {
                                        e.target.value = null
                                      }

                                      if (!isDecreaseAllowed && e.target.value < td.licenseCount) {
                                        e.target.value = null
                                      }

                                    }}
                                    onChange={(e) => {
                                      if (
                                        e.target.value >= td.minQuantity &&
                                        e.target.value <= td.maxQuantity
                                      ) {
                                        setUpdatedQuantityIntermediateErrorState(false);
                                        setUpdatedQuantityIntermediate(e.target.value);
                                        e.target.value < td.maxQuantity ? setIsIncreaseAllowed(true) : setIsIncreaseAllowed(false);
                                        e.target.value > td.minQuantity ? setIsDecreaseAllowed(true) : setIsDecreaseAllowed(false);
                                      } else {
                                        setUpdatedQuantityIntermediateErrorState(true);
                                      }

                                      if (e.target.value <= td.minQuantity) {
                                        setDecreaseQuantity(false);
                                      } else {
                                        setDecreaseQuantity(true);
                                      }
                                    }
                                    }
                                  />

                                  <div className="btn-wrapper">

                                    {isIncreaseAllowed &&
                                      <button className="plus" onClick={() => {

                                        if (licenceQuantity.current.value < td.maxQuantity) {
                                          licenceQuantity.current.value = parseInt(licenceQuantity.current.value) + 1;
                                          setUpdatedQuantityIntermediateErrorState(false);
                                          setUpdatedQuantityIntermediate(licenceQuantity.current.value);
                                        } else {
                                          setUpdatedQuantityIntermediateErrorState(true);
                                        }

                                        console.log(licenceQuantity.current.value < td.maxQuantity);

                                        licenceQuantity.current.value < td.maxQuantity ? setIsIncreaseAllowed(true) : setIsIncreaseAllowed(false);
                                        licenceQuantity.current.value > td.minQuantity ? setIsDecreaseAllowed(true) : setIsDecreaseAllowed(false);
                                      }
                                      }>+</button>}

                                    {!isIncreaseAllowed &&
                                      <button className="plus disabled" disabled>+</button>
                                    }

                                    {isDecreaseAllowed && <button className="minus" onClick={
                                      () => {
                                        {
                                          if (licenceQuantity.current.value > td.minQuantity) {
                                            licenceQuantity.current.value = parseInt(licenceQuantity.current.value) - 1;
                                            setUpdatedQuantityIntermediateErrorState(false);
                                            setUpdatedQuantityIntermediate(licenceQuantity.current.value);
                                          } else {
                                            setUpdatedQuantityIntermediateErrorState(true);
                                          }

                                          licenceQuantity.current.value < td.maxQuantity ? setIsIncreaseAllowed(true) : setIsIncreaseAllowed(false);
                                          licenceQuantity.current.value > td.minQuantity ? setIsDecreaseAllowed(true) : setIsDecreaseAllowed(false);
                                        }
                                      }
                                    }> <span>&nbsp;</span> </button>}

                                    {!isDecreaseAllowed &&
                                      <button className="minus disabled" disabled><span>&nbsp;</span></button>
                                    }
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Table bordered className="quantity-table">
                              <thead>
                                <tr>
                                  <th className="col-md-3 col-sm-3"></th>
                                  <th className="col-md-3 col-sm-3">Current</th>
                                  <th className="col-md-3 col-sm-3">Updated</th>
                                  <th className="col-md-3 col-sm-3">Net</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Quantity</td>
                                  <td>{td.licenseCount}</td>
                                  <td>
                                    {updatedQuantityIntermediate === 0
                                      ? "-"
                                      : updatedQuantityIntermediate}
                                  </td>
                                  {updatedQuantityIntermediate === 0 && (
                                    <td>-</td>
                                  )}
                                  {updatedQuantityIntermediate > 0 && (
                                    <td>
                                      {updatedQuantityIntermediate -
                                        td.licenseCount}
                                    </td>
                                  )}
                                </tr>
                                {showPriceFlag && (
                                  <tr>
                                    <td>Price</td>
                                    <td>
                                      ${Intl.NumberFormat("en-US").format((td.customerPrice * td.licenseCount).toFixed(2))}
                                    </td>
                                    <td>
                                      {updatedQuantityIntermediate === 0
                                        ? ""
                                        : "$"}
                                      {updatedQuantityIntermediate === 0
                                        ? "-"
                                        : Intl.NumberFormat("en-US").format((
                                          td.customerPrice *
                                          updatedQuantityIntermediate
                                        ).toFixed(2))}
                                    </td>
                                    {updatedQuantityIntermediate === 0 && (
                                      <td>-</td>
                                    )}
                                    {updatedQuantityIntermediate > 0 && (
                                      <td>
                                        {(updatedQuantityIntermediate - td.licenseCount) >= 0 ? "$" : "-$"}
                                        {Intl.NumberFormat("en-US").format(Math.abs(
                                          td.customerPrice *
                                          updatedQuantityIntermediate -
                                          td.customerPrice * td.licenseCount
                                        ).toFixed(2))}
                                      </td>
                                    )}
                                  </tr>
                                )}
                              </tbody>
                              <tfoot>
                                {showPriceFlag && (
                                  <tr>
                                    <td
                                      colSpan={3}
                                      className="edit-quantity-popup-total"
                                    >
                                      <strong>Total</strong>
                                    </td>
                                    <td>
                                      {updatedQuantityIntermediate === 0
                                        ? ""
                                        : (updatedQuantityIntermediate - td.licenseCount) >= 0 ? "$" : "-$"}
                                      {updatedQuantityIntermediate === 0 && "-"}
                                      {updatedQuantityIntermediate > 0 &&
                                        Intl.NumberFormat("en-US").format(Math.abs(
                                          td.customerPrice *
                                          updatedQuantityIntermediate -
                                          td.customerPrice * td.licenseCount
                                        ).toFixed(2))}
                                    </td>
                                  </tr>
                                )}
                              </tfoot>
                            </Table>
                            {(
                              td.customerPrice * updatedQuantityIntermediate -
                              td.customerPrice * td.licenseCount
                            ).toFixed(2) <= 0 && (
                                <p className="mb-3 mt-0 text-small text-faded">
                                  *Plan cost is provided under 'Net price', final
                                  cost may vary based on vendor’s billing logic.
                                </p>
                              )}
                            {(
                              td.customerPrice * updatedQuantityIntermediate -
                              td.customerPrice * td.licenseCount
                            ).toFixed(2) > 0 && (
                                <p className="mb-3 mt-0 text-small text-faded">
                                  &nbsp;
                                </p>
                              )}

                            <div className="actions">
                              <div className="d-flex justify-content-end">
                                <Button
                                  className="btn-lg mr-3 mx-3 btn-border-light"
                                  variant="light"
                                  onClick={() => {
                                    // console.log("modal closed ");
                                    close();
                                  }}
                                >
                                  Cancel
                                </Button>
                                {(updatedQuantityIntermediate === 0 ||
                                  updatedQuantityIntermediateErrorState ||
                                  updatedQuantityIntermediate ==
                                  td.licenseCount) && (
                                    <Button
                                      className="btn btn-lg"
                                      variant="light"
                                      disabled
                                    >
                                      Proceed
                                    </Button>
                                  )}
                                {!updatedQuantityIntermediateErrorState &&
                                  updatedQuantityIntermediate > 0 &&
                                  updatedQuantityIntermediate !=
                                  td.licenseCount && (
                                    <Button
                                      className="btn btn-primary btn-lg"
                                      onClick={() => {
                                        // let checkFlag=false;
                                        setIntermediateOrderSummaryArray({
                                          parentPlanName: "",
                                          vendorSubscriptionId:
                                            td.vendorSubscriptionId,
                                          customerPrice: td.customerPrice,
                                          planName: td.planName,
                                          subId: td.subscriptionId,
                                          minQuantity: td.minQuantity,
                                          maxQuantity: td.maxQuantity,
                                          defaultQuantity: td.licenseCount,
                                          updatedQuantity:
                                            updatedQuantityIntermediate,
                                          netQuantity:
                                            updatedQuantityIntermediate -
                                            td.licenseCount,
                                          defaultPrice:
                                            td.customerPrice * td.licenseCount,
                                          updatedPrice: (
                                            td.customerPrice *
                                            updatedQuantityIntermediate
                                          ).toFixed(2),
                                          netPrice: (
                                            td.customerPrice *
                                            updatedQuantityIntermediate -
                                            td.customerPrice * td.licenseCount
                                          ).toFixed(2),
                                        });
                                        // if(orderSummaryArray.length===0){
                                        //     setOrderSummaryArray([intermediateorderSummaryArray]);
                                        // }
                                        // else{
                                        setShowPanel(false);
                                        close();
                                      }}
                                    >
                                      Proceed
                                    </Button>
                                  )}
                              </div>
                            </div>
                          </Container>
                        </div>
                      )}
                    </Popup>
                  </label>
                </td>
                {showPriceFlag && (
                  <td className="manage-subscription-csp-table-data text-right">
                    <strong>${Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(td.customerPrice)}</strong>
                  </td>
                )}
                {showRRPFlag && (
                  <td className="manage-subscription-csp-table-data text-right">
                    <strong>${Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(td.showRrp)}</strong>
                  </td>
                )}
                <td className="manage-subscription-csp-table-data">
                  {td.billingTerm}
                  {/* <br />
                    {td.termDuration && <span className="plan-status">
                        {"( " + td.termDuration + " )"}
                    </span>} */}
                </td>
                <td className="manage-subscription-csp-table-data text-center">
                  {/* {td.subscriptionStatus === "Active" ? (
                        <CheckCircleFill color="#1D874C" size={16} />
                    ) : (
                        <DashCircleFill color="#9C3B35" size={16} />
                    )} */}
                  {td.subscriptionStatus === "Active" && (
                    <CheckCircleFill color="#1D874C" size={16} />
                  )}
                  {td.subscriptionStatus === "Pending" && (
                    <img
                      src={PendingCircleFill}
                      className="pending-circle-fill"
                    />
                  )}
                  {(td.subscriptionStatus === "Suspended" ||
                    td.subscriptionStatus === "Cancelled" || td.subscriptionStatus === "Deleted" || td.subscriptionStatus === "Expired"
                    || td.subscriptionStatus === "Disabled" || td.subscriptionStatus === "Inactive") && (
                      <DashCircleFill color="#9C3B35" size={16} />
                    )}
                  <br />
                  <span className="plan-status">{td.subscriptionStatus}</span>
                </td>
                <td className="manage-subscription-csp-table-data">
                  {td.planType}
                </td>
                <td className="manage-subscription-csp-table-data">
                  {td.subscriptionDate.slice(0, 10)}
                </td>
                <td className="manage-subscription-csp-table-data text-center">
                  <span
                    className="infoIcon"
                    onClick={() => {
                      setIsSubscriptionDetailsPopupOpen(true);
                      GetSubscriptionDetails(td.subscriptionId);
                    }}
                  >
                    &nbsp;
                  </span>
                </td>
                <td className="manage-subscription-csp-table-data text-center">
                  <a
                    onClick={() => {
                      let cloudservice;
                      if (integrationCode === "microsoftsaas") {
                        cloudservice = "Microsoft CSP";
                      }
                      if (integrationCode === "googleworkspace") {
                        cloudservice = "Google Workspace";
                      }
                      setActiveLocalKey("ticketDetails");
                      navigate("/create-support-ticket", {
                        state: {
                          accountId: td.accountId,
                          subId: td.subscriptionId,
                          planName: td.planName,
                          isGlobal: false,
                          cloudServices: cloudservice,
                        },
                      });
                    }}
                  >
                    <span className="ticket">&nbsp;</span>
                  </a>
                </td>
              </tr>
              {expendableRowOpen &&
                !expandableRowLoading &&
                addOnsPlanArray &&
                !AddOnPlanError &&
                td.subscriptionId === subscriptionId &&
                addOnsPlanArray.map((item) => {
                  return (
                    <tr
                      className={`subrow manage-subscription-csp-table-row ${selectedOrderSummaryArray.includes(item.subscriptionId)
                        ? "selectedSubRow"
                        : ""
                        }`}
                    >
                      <td></td>
                      <td className="manage-subscription-csp-table-data">
                        <CircleFill size={6} color="#6A6A6A" className="mx-2" />
                        {item.planName}
                      </td>
                      <td className="manage-subscription-csp-table-data text-right">
                        <label className="d-flex align-items-center justify-content-end">
                          {item.licenseCount}
                          {(item.subscriptionStatus !== "Active" ||
                            item.isTrialSub === true) && (
                              <span className="editpencil mx-3 mr-0 disabled cnotallowed">
                                &nbsp;
                              </span>
                            )}
                          <Popup
                          onOpen={() => {
                            item.licenseCount < item.maxQuantity ? setIsIncreaseAllowed(true) : setIsIncreaseAllowed(false);
                            item.licenseCount > item.minQuantity ? setIsDecreaseAllowed(true) : setIsDecreaseAllowed(false);
                          }
                          }
                            onClose={() => {
                              setUpdatedQuantityIntermediateErrorState(false);
                              setUpdatedQuantityIntermediate(0);
                              setIncreaseQuantity(true);
                              setDecreaseQuantity(true);
                              setIsIncreaseAllowed(true);
                              setIsDecreaseAllowed(true);
                            }}
                            trigger={
                              item.subscriptionStatus === "Active" &&
                              item.isTrialSub === false && (
                                <span className="editpencil mx-3 mr-0">
                                  &nbsp;
                                </span>
                              )
                            }
                            className="custom-modal"
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <div className="header">
                                  <h2>Edit Quantity</h2>
                                  <XLg
                                    size={24}
                                    className="cpointer close-icon-popup"
                                    onClick={close}
                                  />
                                </div>
                                <Container>
                                  <Row>
                                    <Col xs={12} className="mb-4">
                                      <p className="text-regular mb-4">
                                        <span className="text-strong ">
                                          Plan :
                                        </span>{" "}
                                        {item.planName}
                                      </p>
                                    </Col>
                                    <Col md={4} className="col-6">
                                      <p className="mb-0">Enter New Quantity</p>
                                      {!updatedQuantityIntermediateErrorState && (
                                        <p className="text-small text-disabled">
                                          {"( Min " +
                                            item.minQuantity +
                                            ", Max " +
                                            item.maxQuantity +
                                            " )"}
                                        </p>
                                      )}
                                      {updatedQuantityIntermediateErrorState && (
                                        <p className="text-small text-danger">
                                          {"( Min " +
                                            item.minQuantity +
                                            ", Max " +
                                            item.maxQuantity +
                                            " )"}
                                        </p>
                                      )}
                                    </Col>
                                    <Col md={4} className="col-6">
                                      <div class="number-input">

                                        <Form.Control
                                          type="number"
                                          placeholder="Enter Quantity"
                                          defaultValue={item.licenseCount}
                                          min={item.minQuantity}
                                          max={item.maxQuantity}
                                          ref={licenceQuantity}
                                          disabled={!isIncreaseAllowed && !isDecreaseAllowed}
                                          onInput={(e) => {
                                            e.target.value =
                                              !!e.target.value && Math.abs(e.target.value) >= item.minQuantity ? Math.abs(e.target.value) : null;

                                            e.target.value =
                                              !!e.target.value && Math.abs(e.target.value) <= item.maxQuantity ? Math.abs(e.target.value) : null;

                                            if (!isIncreaseAllowed && e.target.value > item.licenseCount) {
                                              e.target.value = null
                                            }

                                            if (!isDecreaseAllowed && e.target.value < item.licenseCount) {
                                              e.target.value = null
                                            }

                                          }}
                                          onChange={(e) => {
                                            if (
                                              e.target.value >= item.minQuantity &&
                                              e.target.value <= item.maxQuantity
                                            ) {
                                              setUpdatedQuantityIntermediateErrorState(false);
                                              setUpdatedQuantityIntermediate(e.target.value);
                                              e.target.value < item.maxQuantity ? setIsIncreaseAllowed(true) : setIsIncreaseAllowed(false);
                                              e.target.value > item.minQuantity ? setIsDecreaseAllowed(true) : setIsDecreaseAllowed(false);
                                            } else {
                                              setUpdatedQuantityIntermediateErrorState(true);
                                            }

                                            if (e.target.value <= item.minQuantity) {
                                              setDecreaseQuantity(false);
                                            } else {
                                              setDecreaseQuantity(true);
                                            }
                                          }
                                          }
                                        />

                                        <div className="btn-wrapper">

                                          {isIncreaseAllowed &&
                                            <button className="plus" onClick={() => {

                                              if (licenceQuantity.current.value < item.maxQuantity) {
                                                licenceQuantity.current.value = parseInt(licenceQuantity.current.value) + 1;
                                                setUpdatedQuantityIntermediateErrorState(false);
                                                setUpdatedQuantityIntermediate(licenceQuantity.current.value);
                                              } else {
                                                setUpdatedQuantityIntermediateErrorState(true);
                                              }

                                              console.log(licenceQuantity.current.value < item.maxQuantity);

                                              licenceQuantity.current.value < item.maxQuantity ? setIsIncreaseAllowed(true) : setIsIncreaseAllowed(false);
                                              licenceQuantity.current.value > item.minQuantity ? setIsDecreaseAllowed(true) : setIsDecreaseAllowed(false);
                                            }
                                            }>+</button>}

                                          {!isIncreaseAllowed &&
                                            <button className="plus disabled" disabled>+</button>
                                          }

                                          {isDecreaseAllowed && <button className="minus" onClick={
                                            () => {
                                              {
                                                if (licenceQuantity.current.value > item.minQuantity) {
                                                  licenceQuantity.current.value = parseInt(licenceQuantity.current.value) - 1;
                                                  setUpdatedQuantityIntermediateErrorState(false);
                                                  setUpdatedQuantityIntermediate(licenceQuantity.current.value);
                                                } else {
                                                  setUpdatedQuantityIntermediateErrorState(true);
                                                }

                                                licenceQuantity.current.value < item.maxQuantity ? setIsIncreaseAllowed(true) : setIsIncreaseAllowed(false);
                                                licenceQuantity.current.value > item.minQuantity ? setIsDecreaseAllowed(true) : setIsDecreaseAllowed(false);
                                              }
                                            }
                                          }> <span>&nbsp;</span> </button>}

                                          {!isDecreaseAllowed &&
                                            <button className="minus disabled" disabled><span>&nbsp;</span></button>
                                          }
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Table bordered className="quantity-table">
                                    <thead>
                                      <tr>
                                        <th className="col-md-3 col-sm-3"></th>
                                        <th className="col-md-3 col-sm-3">
                                          Current
                                        </th>
                                        <th className="col-md-3 col-sm-3">
                                          Updated
                                        </th>
                                        <th className="col-md-3 col-sm-3">
                                          Net
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Quantity</td>
                                        <td>{item.licenseCount}</td>
                                        <td>
                                          {updatedQuantityIntermediate === 0
                                            ? "-"
                                            : updatedQuantityIntermediate}
                                        </td>
                                        {updatedQuantityIntermediate === 0 && (
                                          <td>-</td>
                                        )}
                                        {updatedQuantityIntermediate > 0 && (
                                          <td>
                                            {updatedQuantityIntermediate -
                                              item.licenseCount}
                                          </td>
                                        )}
                                      </tr>
                                      {showPriceFlag && (
                                        <tr>
                                          <td>Price</td>
                                          <td>
                                            $
                                            {Intl.NumberFormat("en-US").format((item.customerPrice *
                                              item.licenseCount).toFixed(2))}
                                          </td>
                                          <td>
                                            {updatedQuantityIntermediate === 0
                                              ? ""
                                              : "$"}
                                            {updatedQuantityIntermediate === 0
                                              ? "-"
                                              : Intl.NumberFormat("en-US").format((
                                                item.customerPrice *
                                                updatedQuantityIntermediate
                                              ).toFixed(2))}
                                          </td>
                                          {updatedQuantityIntermediate ===
                                            0 && <td>-</td>}
                                          {updatedQuantityIntermediate > 0 && (
                                            <td>
                                              {(updatedQuantityIntermediate - item.licenseCount) >= 0 ? "$" : "-$"}
                                              {Intl.NumberFormat("en-US").format(Math.abs(
                                                item.customerPrice *
                                                updatedQuantityIntermediate -
                                                item.customerPrice *
                                                item.licenseCount
                                              ).toFixed(2))}
                                            </td>
                                          )}
                                        </tr>
                                      )}
                                    </tbody>
                                    <tfoot>
                                      {showPriceFlag && (
                                        <tr>
                                          <td
                                            colSpan={3}
                                            className="edit-quantity-popup-total"
                                          >
                                            <strong>Total</strong>
                                          </td>
                                          <td>
                                            {updatedQuantityIntermediate === 0
                                              ? ""
                                              : (updatedQuantityIntermediate - item.licenseCount) >= 0 ? "$" : "-$"}
                                            {updatedQuantityIntermediate ===
                                              0 && "-"}
                                            {updatedQuantityIntermediate > 0 &&
                                              Intl.NumberFormat("en-US").format(Math.abs(
                                                item.customerPrice *
                                                updatedQuantityIntermediate -
                                                item.customerPrice *
                                                item.licenseCount
                                              ).toFixed(2))}
                                          </td>
                                        </tr>
                                      )}
                                    </tfoot>
                                  </Table>
                                  {(
                                    item.customerPrice *
                                    updatedQuantityIntermediate -
                                    item.customerPrice * item.licenseCount
                                  ).toFixed(2) <= 0 && (
                                      <p className="mb-3 mt-0 text-small text-faded">
                                        *Plan cost is provided under 'Net price',
                                        final cost may vary based on vendor’s
                                        billing logic.
                                      </p>
                                    )}
                                  {(
                                    item.customerPrice *
                                    updatedQuantityIntermediate -
                                    item.customerPrice * item.licenseCount
                                  ).toFixed(2) > 0 && (
                                      <p className="mb-3 mt-0 text-small text-faded">
                                        &nbsp;
                                      </p>
                                    )}
                                  <div className="actions">
                                    <div className="d-flex justify-content-end">
                                      <Button
                                        className="btn-lg mr-3 mx-3 btn-border-light"
                                        variant="light"
                                        onClick={() => {
                                          // console.log(
                                          //   "modal closed "
                                          // );
                                          close();
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                      {((updatedQuantityIntermediate - item.licenseCount === 0 || updatedQuantityIntermediate === 0) && (updatedQuantityIntermediateErrorState ||
                                        updatedQuantityIntermediate === 0 ||
                                        updatedQuantityIntermediate ==
                                        item.licenseCount)) && (
                                          <Button
                                            className="btn btn-lg"
                                            variant="light"
                                            disabled
                                          >
                                            Proceed
                                          </Button>
                                        )}
                                      {((updatedQuantityIntermediate - item.licenseCount > 0) || (!updatedQuantityIntermediateErrorState &&
                                        updatedQuantityIntermediate > 0 &&
                                        updatedQuantityIntermediate !=
                                        item.licenseCount)) && (
                                          <Button
                                            className="button btn-lg"
                                            onClick={() => {
                                              // let checkFlag=false;
                                              setIntermediateOrderSummaryArray({
                                                parentPlanName: parentAddOns,
                                                vendorSubscriptionId:
                                                  item.vendorSubscriptionId,
                                                customerPrice:
                                                  item.customerPrice,
                                                planName: item.planName,
                                                subId: item.subscriptionId,
                                                minQuantity: item.minQuantity,
                                                maxQuantity: item.maxQuantity,
                                                defaultQuantity:
                                                  item.licenseCount,
                                                updatedQuantity:
                                                  updatedQuantityIntermediate,
                                                netQuantity:
                                                  updatedQuantityIntermediate -
                                                  item.licenseCount,
                                                defaultPrice:
                                                  item.customerPrice *
                                                  item.licenseCount,
                                                updatedPrice: (
                                                  item.customerPrice *
                                                  updatedQuantityIntermediate
                                                ).toFixed(2),
                                                netPrice: (
                                                  item.customerPrice *
                                                  updatedQuantityIntermediate -
                                                  item.customerPrice *
                                                  item.licenseCount
                                                ).toFixed(2),
                                              });
                                              setShowPanel(false);
                                              close();
                                            }}
                                          >
                                            Proceed
                                          </Button>
                                        )}
                                    </div>
                                  </div>
                                </Container>
                              </div>
                            )}
                          </Popup>
                        </label>
                      </td>
                      {showPriceFlag && (
                        <td className="manage-subscription-csp-table-data text-right">
                          <strong>${Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(item.customerPrice)}</strong>
                        </td>
                      )}
                      <td className="manage-subscription-csp-table-data">
                        {item.billingTerm}
                        {/* <br />
                            {item.termDuration && <span className="plan-status">
                            {"( " + item.termDuration + " )"}
                            </span>} */}
                      </td>
                      <td className="manage-subscription-csp-table-data text-center">
                        {/* {item.subscriptionStatus === "Active" ? (
                            <CheckCircleFill
                                color="#22633F"
                                size={16}
                            />
                            ) : (
                            <DashCircleFill
                                color="#B13E2C"
                                size={16}
                            />
                            )} */}
                        {item.subscriptionStatus === "Active" && (
                          <CheckCircleFill color="#1D874C" size={16} />
                        )}
                        {item.subscriptionStatus === "Pending" && (
                          <img
                            src={PendingCircleFill}
                            className="pending-circle-fill"
                          />
                        )}
                        {(item.subscriptionStatus === "Suspended" ||
                          item.subscriptionStatus === "Cancelled" || item.subscriptionStatus === "Deleted" || item.subscriptionStatus === "Expired"
                          || item.subscriptionStatus === "Disabled" || item.subscriptionStatus === "Inactive") && (
                            <DashCircleFill color="#9C3B35" size={16} />
                          )}
                        <br />
                        <span className="plan-status">
                          {item.subscriptionStatus}
                        </span>
                      </td>
                      <td className="manage-subscription-csp-table-data ">
                        {item.planType}
                      </td>
                      <td className="manage-subscription-csp-table-data">
                        {item.subscriptionDate.slice(0, 10)}
                      </td>
                      <td className="manage-subscription-csp-table-data text-center">
                        <span
                          className="infoIcon"
                          onClick={() => {
                            setIsSubscriptionDetailsPopupOpen(true);
                            GetSubscriptionDetails(item.subscriptionId);
                          }}
                        >
                          &nbsp;
                        </span>
                      </td>
                      <td className="manage-subscription-csp-table-data text-center">
                        <a
                          onClick={() => {
                            let cloudservice;
                            if (integrationCode === "microsoftsaas") {
                              cloudservice = "Microsoft CSP";
                            }
                            if (integrationCode === "googleworkspace") {
                              cloudservice = "Google Workspace";
                            }
                            setActiveLocalKey("ticketDetails");
                            navigate("/create-support-ticket", {
                              state: {
                                accountId: td.accountId,
                                subId: td.subscriptionId,
                                planName: td.planName,
                                isGlobal: false,
                                cloudServices: cloudservice,
                              },
                            });
                          }}
                        >
                          <span className="ticket">&nbsp;</span>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              {expendableRowOpen &&
                expandableRowLoading &&
                addOnsPlanArray &&
                !AddOnPlanError &&
                td.subscriptionId === subscriptionId &&
                <tr
                  className={`subrow manage-subscription-csp-table-row no-hover`}
                >
                  <td colSpan="10">
                    <p className="mb-0 py-3">
                      <center>Loading . . . </center>
                    </p>
                  </td>
                </tr>
              }
              {expendableRowOpen &&
                !expandableRowLoading &&
                !addOnsPlanArray &&
                AddOnPlanError &&
                td.subscriptionId === subscriptionId && (
                  <tr
                    className={`subrow manage-subscription-csp-table-row no-hover`}
                  >
                    <td colSpan="10">
                      <p className="mb-0 py-3">
                        <center>
                          AddOns plans not found, Please Try again Later
                        </center>
                      </p>
                    </td>
                  </tr>
                )}
            </>
          );
        })}
      </tbody>
    </Table>

  );
};

export default SubscriptionTable;