import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { api } from "../../../Utils/API";
import { PROFILE_DETAILS, MANAGEMENT_CONSOLE_ACCOUNTS_URL, MANAGEMENT_CONSOLE_CLOUD_SERVICE_MANAGEMENT, MANAGEMENT_CONSOLE_SUBSCRIPTION_ADD_ONS, 
  EDIT_QUANTITY} from "../../../Utils/GlobalConstants";
import {Container,Row,Col,NavDropdown,Alert} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "reactjs-popup/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {Check2Circle,XCircle } from "react-bootstrap-icons";
import CmsDetailsContext from "../../../Context/CmsDetails/CmsDetailsContext";
import InputGroup from 'react-bootstrap/InputGroup';
import EmptyCloudServicesIllustration from '../../../Assets/Images/EmptyCloudServices.svg';
import EmptySubscriptionTableIllustration from '../../../Assets/Images/EmptySubscriptionTable.svg';
import EmptyOrderSummaryIllustration from '../../../Assets/Images/EmptyOrderSummary.svg';
import EmptyDataTableFilterSearch from '../../../Assets/Images/EmptyDataTableFilterSearch.svg';
import TermsAndConditions from "../TermsAndConditions";
import SubscriptionTable from "../../../Components/Table/SubscriptionTable";
import OrderSummary from "../../../Components/OrderSummary";
import GWSViewCloudPortalDetails from "../../../Components/Popup/ViewCloudPortalDetails/GWSViewCloudPortalDetails";
import CSPViewCloudPortalDetails from "../../../Components/Popup/ViewCloudPortalDetails/CSPViewCloudPortalDetails";
import GlobalFilterBox from "../../../Components/GlobalFilterBox";
import EmptyCloudServices from "../../../Components/EmptyStates/EmptyCloudServices";
import EmptySubscription from "../../../Components/EmptyStates/EmptySubscription";
import NoPlanName from "../../../Components/EmptyStates/NoPlanName";
import NoData from "../../../Components/EmptyStates/NoData";
import SubscriptionTableTryAgain from "../../../Components/EmptyStates/SubscriptionTableTryAgainLater";

const ManagementConsoleCustomer = (props) => {
  const navigate = useNavigate();  // React Router DOM v6

  // USE STATES

  const [showFilterBox, setShowFilterBox] = useState(false); // Showing Global Filter Box
  // MANAGEMENT CONSOLE SUBSCRIPTION TABLE DATA HEADERS
  let ColumnNameMicrosoftCSP = [" ","Plan","Quantity","Unit Price","RRP (Inc GST)","Billing Term","Status","Subscription Type","Start Date","Info",
  "Support Tickets"];

  const [dropdownData, setDropdownData] = useState([]); // My cloud Services Data
  const [accountId, setAccountId] = useState(""); // Storing Account Id of selected User
  const [integrationCode, setIntegrationCode] = useState(""); // Storing integration code like googleworkspace and microsoftsaas

  const [subscriptionTable, setSubscriptionTable] = useState(); // Storing Subscription Table Data
  const [subscriptionTableDuplicate, setSubscriptionTableDuplicate] = useState(); // Storing Subscription Table Data Duplicate
  const [subscriptionTableMasterDatabase, setSubscriptionTableMasterDatabase] = useState(); // Storing Subscription Table Data for Filters
  

  const [subscriptionId, setSubscriptionId] = useState(""); // Storing Subscription Id for the expandable rows
  const [expendableRowOpen, setExpendableRowOpen] = useState(false); // To expand expandable rows in subscription Table

  const [searchValueTable, setSearchValueTable] = useState(""); // Storing searching value in the search input box

  const [updatedQuantityIntermediate, setUpdatedQuantityIntermediate] = useState(0); // Storing Updated quantity as Intermediate

  const [updatedQuantityIntermediateErrorState, setUpdatedQuantityIntermediateErrorState] = useState(false); // Updated Quantity Intermediate which will set order summary array

  const [orderSummaryArray, setOrderSummaryArray] = useState([]); // Storing Order Summaries
  const [intermediateorderSummaryArray, setIntermediateOrderSummaryArray] = useState(); // Storing Intermediate Order Summaries

  const [orderSummaryQuantityCount, setOrderSummaryQuantityCount] = useState(0); // Order Summary Total Net Quantity Count
  const [orderSummaryTotalPriceCount, setOrderSummaryTotalPriceCount] = useState(0); // Order Summary Total Net Price Count
  let orderSummaryQuantityCountIntermediate = 0; // Order Summary Total Net Quantity Count Intermediate
  let orderSummaryTotalPriceCountIntermediate = 0.0; // Order Summary Total Net Price Count Intermediate

  const [addOnsSubscriptionId, setAddOnsSubscriptionId] = useState(); // Storing Parent Subscription id to view add ons
  const [addOnsPlanArray, setAddOnsPlanArray] = useState([]); // Storing Add ons Values Array

  // Header Authorization for API
  const config = {
    headers: {
      "Authorization": `${sessionStorage.getItem("token_type")} ${sessionStorage.getItem("access_token")}`,
    },
  };

  // CLOUD SERVICE MANAGEMENT CUSTOMER DETAILS
  const [customerData, setCustomerData] = useState("Loading...");
  const [startDate, setStartDate] = useState("Loading...");
  const [portal, setPortal] = useState("Loading...");
  const [domainName, setDomainName] = useState("Loading...");
  const [initialPassword, setInitialPassword] = useState("Loading...");
  const [totalLicences, setTotalLicences] = useState("...");
  const [totalAddOns, setTotalAddOns] = useState("...");
  const [passwordType, setPasswordType] = useState(["password", ""]);
  const [cloudIdentity, setCloudIdentity] = useState("Loading...");
  const [adminName, setAdminName] = useState("Loading...");
  const [serviceName, setServiceName] = useState("Loading...");

  const [showPanel, setShowPanel] = useState(true); // Showing Order Summary div

  const globalData = useContext(CmsDetailsContext); // Use Context Global Data

  const [showPriceFlag, setShowPriceFlag] = useState(); // Show Price from CMS Portal

  const [showRRPFlag, setShowRRPFlag] = useState(); // Show Price from CMS Portal

  const [parentAddOns, setParentAddOns] = useState(""); // Plan Name for expandable row set up

  // SUBSCRIPTION DETAILS SERVICE MANAGEMENT CUSTOMER DETAILS
  const [subscriptionDetailsPlanName, setSubscriptionDetailsPlanName] = useState("Loading...");
  const [ subscriptionDetailsVendorSubscriptionId,setSubscriptionDetailsVendorSubscriptionId] = useState("Loading...");
  const [subscriptionDetailsLicenceAutoRenewal,setSubscriptionDetailsLicenceAutoRenewal] = useState("Loading...");
  const [subscriptionDetailsStartDate, setSubscriptionDetailsStartDate] = useState("Loading...");
  const [subscriptionDetailsEndDate, setSubscriptionDetailsEndDate] = useState("Loading...");
  const [subscriptionDetailsRRP, setSubscriptionDetailsRRP] = useState("Loading...");
  const [subscriptionDetailsIsTrial, setSubscriptionDetailsIsTrial] = useState("Loading...");
  const [subscriptionDetailsTrialEndDate, setSubscriptionDetailsTrialEndDate] = useState("Loading...");

  const [isSubscriptionDetailsPopupOpen, setIsSubscriptionDetailsPopupOpen] = useState(false); // Subscription Details Popup Box
  const [subscriptionDetailIsLoadingFlag, setSubscriptionDetailIsLoadingFlag] = useState(false) // Subscription is Loading Popup

  const [copied, setCopied] = useState("Click to copy"); // Copy Use State

  // Total in Order Summary
  const [orderSummaryEditIntermediateFlag, setOrderSummaryEditIntermediateFlag] = useState(false); 
  const [orderSummaryEditFinalFlag, setOrderSummaryEditFinalFlag] = useState(false);

  const [searchIconFlag, setSearchIconFlag] = useState(false); // Search Icon 

  // GLOBAL AND LOCAL FILTERS 

  const [billingTermFilter, setBillingTermFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [subscriptionTypeFilter, setSubscriptionTypeFilter] = useState([]);

  const [billingTermAll, setBillingTermAll] = useState(false);
  const [statusAll, setStatusAll] = useState(false);
  const [subscriptionTypeAll, setSubscriptionTypeAll] = useState(false);

  const [billingTermListGlobal, setBillingTermListGlobal] = useState({});
  const [statusListGlobal, setStatusListGlobal] = useState([]);
  const [subscriptionTypeListGlobal, setSubscriptionTypeListGlobal] = useState([]);

  const [billingTermListLocal, setBillingTermListLocal] = useState({});
  const [statusListLocal, setStatusListLocal] = useState([]);
  const [subscriptionTypeListLocal, setSubscriptionTypeListLocal] = useState([]);

  const [billingTermAllLocal, setBillingTermAllLocal] = useState(false);
  const [statusAllLocal, setStatusAllLocal] = useState(false);
  const [subscriptionTypeAllLocal, setSubscriptionTypeAllLocal] = useState(false);

  const [filterFlag, setFilterFlag] = useState(false);

  const [filterFlagLocalSubscriptionType, setFilterFlagLocalSubscriptionType] = useState(false);
  const [filterFlagLocalStatus, setFilterFlagLocalStatus] = useState(false);
  const [filterFlagLocalBilling, setFilterFlagLocalBilling] = useState(false);
  
  const [localFilterOnChangeFlag, setLocalFilterOnChangeFlag] = useState(false);
  const [globalToLocalAllItemFlag, setGlobalToLocalAllItemFlag] = useState(false);
  
  const [isGlobalLacalChangeAPIFlag, setIsGlobalLacalChangeAPIFlag] = useState(false);
  const [isAllLocalChangeAPIFlag, setIsAllLocalChangeAPIFlag] = useState(false);
  const [isPendingRefreshFlag, setIsPendingRefreshFlag] = useState(false); // For Refreshing till we have pending status


  const [editOrderNoteConfirmation, setEditOrderNoteConfirmation] = useState(""); //Order Note Confirm Order Summary Popup

  const [selectedOrderSummaryArray, setSelectedOrderSummaryArray] = useState([]); // Selected Order Summary Array to display blue color to selected item

  const [expandableRowLoading, setExpandableRowLoading] = useState(false);  // Add on Expandable Loading

  const [AddOnPlanError, setAddOnPlanError] = useState(false);    // Empty add ons plan Error Message

  // Empty States Validation

  const [searchEmptySubscriptionTable, setSearchEmptySubscriptionTable] = useState(false); // Search Empty
  const [emptySubscriptionTable, setEmptySubscriptionTable] = useState(false); // No Subscription Table
  const [emptyDropdownMyCloudSrvicesflag, setEmptyDropdownMyCloudSrvicesflag] = useState(false); // NO Cloud service
  const [emptyFilterSubscriptionTable, setEmptyFilterSubscriptionTable] = useState(false); // Filter Empty Data
  const [subscriptionTableTryAgain, setSubscriptionTableTryAgain] = useState(false); // No Subscription Table Data from Backend

  // Toast
  const [toastSuccess, setToastSuccess] = useState(false);
  const [toastSuccessMessage, setToastSuccessMessage] = useState("");
  const [toastError, setToastError] = useState(false);
  const [toastErrorMessage, setToastErrorMessage] = useState("");

  const [showTermsAndConditionFlag, setShowTermsAndConditionFlag] = useState();   // Terms and condition Popup

  const [refreshPageIndividualUpdate, setRefreshPageIndividualUpdate] = useState();

  const [orderSummaryProcessingButtonCheck, setOrderSummaryProcessingButtonCheck] = useState(false);

  const [sortStartDateFlag, setSortStartDateFlag] = useState("DESC");

  const [isProceed, setIsProceed] = useState(false);

  // Logout after expiry time
  useEffect(() => {
    // console.log("Time : ",sessionStorage.getItem("Expire_time"))
    setTimeout(() => {
      props.setCustomerIsAuthenticated(false);
      sessionStorage.clear();
      props.setActiveLocalKey('default');
      navigate("/");
    }
      , sessionStorage.getItem("Expire_time"));
  }, []);

  // Unauthorised user navigated to login page
  useEffect(() => {
    if (props.customerIsAuthenticated === false) {
      navigate("/");
    }
  }, [])

  async function GetShowPriceFlag() {
    await api.get(PROFILE_DETAILS, config).then(resp => {
      setShowPriceFlag(resp.data.showPrice);
      setShowRRPFlag(resp.data.showRrp);
    })
      .catch(error => {
        if (error.response.status == 401) {
          // console.log("Error 401");
          Logout();
        }
      });
  }

  // Setting Global Data
  useEffect(() => {
    if (globalData) {
      GetShowPriceFlag();
      // setShowPriceFlag(globalData.showPrice);
      setShowTermsAndConditionFlag(globalData.termsConditionsVersion);
      // console.log("Show Price Flag : ", globalData.termsConditionsVersion);
    }
  }, [globalData]);

  //  Logout Function
  function Logout() {
    props.setCustomerIsAuthenticated(false);
    if (props.customerIsAuthenticated) { navigate("/") };
     sessionStorage.clear(); 
     localStorage.clear();
     props.setActiveLocalKey('default')
  }

  // Function to store My cloud Services Data
  async function GetTableData() {
    // console.log("Local Storage : ", config);
    await api
      .get(MANAGEMENT_CONSOLE_ACCOUNTS_URL, config)
      .then((resp) => {
        if (resp.status == 204) {
          setEmptyDropdownMyCloudSrvicesflag(true);
        } 
        else {
          setEmptyDropdownMyCloudSrvicesflag(false);
          // console.log("Data : ", resp.data[0].integrationCode);
          setDropdownData(resp.data);
          setAccountId(resp.data[0].accountId);
          setIntegrationCode(resp.data[0].integrationCode);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          // console.log("Error 401");
          Logout();
        }
        // console.log(error.response.status);
      });
  }

  // Function to store Subscription Table Data
  async function GetTableDatas() {

    if (accountId) {
      let statusData=[];
      let pendingCountSub=0;
      let data = {
        "accountId": accountId,
        "planName": "",
        "status": [],
        "subscriptionType": [],
        "billingTerm": [],
      }
      // setEmptyTableCheck(true);
      // console.log("started table data");
      await api
        .get(MANAGEMENT_CONSOLE_CLOUD_SERVICE_MANAGEMENT+accountId, config)
        .then((resp) => {
          if (resp.status == 204) {
            setSubscriptionTableTryAgain(false);
            setEmptySubscriptionTable(true);
            setIsGlobalLacalChangeAPIFlag(false);
          }
          else {
            setEmptyFilterSubscriptionTable(false);
            setIsGlobalLacalChangeAPIFlag(false);
            setEmptySubscriptionTable(false);
            setSubscriptionTableTryAgain(false);
            // console.log("Main Table Data : ", resp.data);
            setSubscriptionTable(resp.data.subscriptions);
            setSubscriptionTableDuplicate(resp.data.subscriptions);
            setSubscriptionTableMasterDatabase(resp.data.subscriptions);
            resp.data.subscriptions.map((td)=>{
              statusData.push(td.subscriptionStatus);
            })
            if(statusData.includes("Pending")){
              setIsPendingRefreshFlag(true);
              statusData.forEach((item)=>{
                if(item==="Pending"){
                  pendingCountSub++
                }
              })
              if(pendingCountSub!=refreshPageIndividualUpdate){
                setRefreshPageIndividualUpdate(pendingCountSub);
              }
            }
            else{
              setIsPendingRefreshFlag(false);
            }
            setBillingTermFilter(resp.data.searchFitlers.billingTerm);
            setStatusFilter(resp.data.searchFitlers.status);
            setSubscriptionTypeFilter(resp.data.searchFitlers.subscriptionType);
            // console.log(billingTermFilter,statusFilter,subscriptionTypeFilter);
            resp.data.searchFitlers.billingTerm.map((item) => {
              setBillingTermListGlobal((prev) => ({
                billingTermGlobal: {
                  ...prev.billingTermGlobal, [item]: false
                }
              }));
            })
            resp.data.searchFitlers.status.map((item) => {
              setStatusListGlobal((prev) => ({
                statusListGlobal: {
                  ...prev.statusListGlobal, [item]: false
                }
              }));
            })
            resp.data.searchFitlers.subscriptionType.map((item) => {
              setSubscriptionTypeListGlobal((prev) => ({
                subscriptionTypeListGlobal: {
                  ...prev.subscriptionTypeListGlobal, [item]: false
                }
              }));
            })
            resp.data.searchFitlers.billingTerm.map((item) => {
              setBillingTermListLocal((prev) => ({
                billingTermListLocal: {
                  ...prev.billingTermListLocal, [item]: false
                }
              }));
            })
            resp.data.searchFitlers.status.map((item) => {
              setStatusListLocal((prev) => ({
                statusListLocal: {
                  ...prev.statusListLocal, [item]: false
                }
              }));
            })
            resp.data.searchFitlers.subscriptionType.map((item) => {
              setSubscriptionTypeListLocal((prev) => ({
                subscriptionTypeListLocal: {
                  ...prev.subscriptionTypeListLocal, [item]: false
                }
              }));
            })
            setFilterFlag(false);
            // setShowFilterBox(false);
            // console.log(d);
          }
        })
        .catch((error) => {
          // handle error
          // console.log(error);
          if (error.response.status == 401) {
            // console.log("Error 401");
            Logout();
          }
          if (error.response.status == 500) {
            setSubscriptionTableTryAgain(true);
          }
        });
    }
  }

  // Clear Global Function
  async function ClearGolbalFunction() {
    if (accountId) {
      setEmptyFilterSubscriptionTable(false);
      setIsGlobalLacalChangeAPIFlag(false);
      setEmptySubscriptionTable(false);
      setSubscriptionTableTryAgain(false);
      // console.log("Main Table Data : ", resp.data);

     
      // console.log(billingTermFilter,statusFilter,subscriptionTypeFilter);
      billingTermFilter.map((item) => {
        setBillingTermListGlobal((prev) => ({
          billingTermGlobal: {
            ...prev.billingTermGlobal, [item]: false
          }
        }));
      })
      statusFilter.map((item) => {
        setStatusListGlobal((prev) => ({
          statusListGlobal: {
            ...prev.statusListGlobal, [item]: false
          }
        }));
      })
      subscriptionTypeFilter.map((item) => {
        setSubscriptionTypeListGlobal((prev) => ({
          subscriptionTypeListGlobal: {
            ...prev.subscriptionTypeListGlobal, [item]: false
          }
        }));
      })
      billingTermFilter.map((item) => {
        setBillingTermListLocal((prev) => ({
          billingTermListLocal: {
            ...prev.billingTermListLocal, [item]: false
          }
        }));
      })
      statusFilter.map((item) => {
        setStatusListLocal((prev) => ({
          statusListLocal: {
            ...prev.statusListLocal, [item]: false
          }
        }));
      })
      subscriptionTypeFilter.map((item) => {
        setSubscriptionTypeListLocal((prev) => ({
          subscriptionTypeListLocal: {
            ...prev.subscriptionTypeListLocal, [item]: false
          }
        }));
      })
      setFilterFlag(false);
      // setShowFilterBox(false);
      // console.log(d);
      if(searchValueTable.length>0){
        console.log(searchValueTable);
        let searchSubscriptionTable = []
        subscriptionTableMasterDatabase.map((search)=>{
          if((search.planName.toLowerCase()).includes(searchValueTable.toLowerCase())){
            searchSubscriptionTable.push(search);
          }
        })
        console.log(searchSubscriptionTable);
        setSubscriptionTable(searchSubscriptionTable);
      }
      else{
        console.log("No");
        setSubscriptionTable(subscriptionTableMasterDatabase);
      }
    }
  }

  // Function to refresh Subscription Table till status is pending
  async function GetTableRefreshOrder() {
    if (accountId) {
      let statusData=[];
      let pendingCountSub=0;
      let data = {
        "accountId": accountId,
        "planName": "",
        "status": [],
        "subscriptionType": [],
        "billingTerm": [],
      }
      // setEmptyTableCheck(true);
      // console.log("started table data");
      await api
        .get(MANAGEMENT_CONSOLE_CLOUD_SERVICE_MANAGEMENT+accountId, config)
        .then((resp) => {
            setSubscriptionTableDuplicate(resp.data.subscriptions);
            (resp.data.subscriptions).map((td)=>{
              statusData.push(td.subscriptionStatus);
            })
            if(statusData.includes("Pending")){
              setIsPendingRefreshFlag(true);
              statusData.forEach((item)=>{
                if(item==="Pending"){
                  pendingCountSub++
                }
              })
              if(pendingCountSub!=refreshPageIndividualUpdate){
                setRefreshPageIndividualUpdate(pendingCountSub);
              }
            }
            else{
              setIsPendingRefreshFlag(false);
              GetTableDatas();
              GetCustomerData();
            }
        })
        .catch((error) => {
          // handle error
          // console.log(error);
          if (error.response.status == 401) {
            // console.log("Error 401");
            Logout();
          }
        });
    }
  }

  // Global Filter Data Function
  async function GetFilterData(status, subscriptionType, billingTerm) {
    let subTableData = [];
    if (accountId) {
      subscriptionTableMasterDatabase.filter((td)=>{
        if(subscriptionType.length===0 && billingTerm.length===0 && status.length>0){
          status.map((item)=>{
            if(td.subscriptionStatus===item){
              subTableData.push(td);
            }
          })
        }
        if(subscriptionType.length===0 && billingTerm.length>0 && status.length===0){
          billingTerm.map((item)=>{
            if(td.billingTerm===item){
              subTableData.push(td);
            }
          })
        }
        if(subscriptionType.length>0 && billingTerm.length===0 && status.length===0){
          subscriptionType.map((item)=>{
            if(td.planType===item){
              subTableData.push(td);
            }
          })
        }
        if(status.length>0 && billingTerm.length>0 && subscriptionType.length>0){
          status.map((item1)=>{
            billingTerm.map((item2)=>{
              subscriptionType.map((item3)=>{
                if(td.planType===item3 && td.billingTerm===item2 && td.subscriptionStatus===item1){
                  subTableData.push(td);
                }
              })
            })
          })
        }
        if(status.length>0 && billingTerm.length>0 && subscriptionType.length===0){
          status.map((item1)=>{
            billingTerm.map((item2)=>{
                if(td.billingTerm===item2 && td.subscriptionStatus===item1){
                  subTableData.push(td);
                }
              })
          })
        }
        if(status.length>0 && billingTerm.length===0 && subscriptionType.length>0){
          status.map((item1)=>{
              subscriptionType.map((item3)=>{
                if(td.planType===item3 && td.subscriptionStatus===item1){
                  subTableData.push(td);
                }
              })
          })
        }
        if(status.length===0 && billingTerm.length>0 && subscriptionType.length>0){
            billingTerm.map((item2)=>{
              subscriptionType.map((item3)=>{
                if(td.planType===item3 && td.billingTerm===item2){
                  subTableData.push(td);
                }
              })
            })
        }
      })
      let subTableSearchData = [];
      if(searchValueTable.length>0){subTableData.map((search)=>{
        if((search.planName.toLowerCase()).includes(searchValueTable.toLowerCase())){
          subTableSearchData.push(search);
        }
      })}
      else{
        subTableSearchData=subTableData;
      }
      if((subTableSearchData.length)>0){
        setEmptyFilterSubscriptionTable(false);
        setSubscriptionTable(subTableSearchData);
        console.log(subTableSearchData.length);
        setFilterFlag(true);
        setShowFilterBox(!showFilterBox);
        setIsGlobalLacalChangeAPIFlag(false);
        setIsAllLocalChangeAPIFlag(false);
      }
      else{
        setEmptyFilterSubscriptionTable(true);
        setShowFilterBox(!showFilterBox);
        setFilterFlag(true);
        setIsGlobalLacalChangeAPIFlag(false);
        setIsPendingRefreshFlag(false);
      }
      // let data = {
      //   "accountId": accountId,
      //   "planName": searchValueTable,
      //   "status": status,
      //   "subscriptionType": subscriptionType,
      //   "billingTerm": billingTerm,
      // }
      // await api
      //   .post(MANAGEMENT_CONSOLE_CLOUD_SERVICE_MANAGEMENT, data, config)
      //   .then((resp) => {
      //     // console.log("Main Table Data : ", resp.data);
      //     if (resp.status == 204) {
      //       setEmptyFilterSubscriptionTable(true);
      //       setShowFilterBox(!showFilterBox);
      //       setFilterFlag(true);
      //       setIsGlobalLacalChangeAPIFlag(false);
      //       setIsPendingRefreshFlag(false);
      //     }
      //     else {
      //       setEmptyFilterSubscriptionTable(false);
      //       setSubscriptionTable(resp.data.subscriptions);
      //       setFilterFlag(true);
      //       setShowFilterBox(!showFilterBox);
      //       setIsGlobalLacalChangeAPIFlag(false);
      //     }

      //   })
      //   .catch((error) => {
      //     // handle error
      //     // console.log(error);
      //     if (error.response.status == 401) {
      //       // console.log("Error 401");
      //       Logout();
      //     }
      //     else{
      //       setIsGlobalLacalChangeAPIFlag(false);
      //     }
      //   });
    }
  }

  // Local Filter Data Function
  async function GetFilterDataLocal(status, subscriptionType, billingTerm) {
    let subTableData = [];
    console.log(status, subscriptionType, billingTerm);
    if (accountId) {
      if(subscriptionType.length===0 && billingTerm.length===0 && status.length===0){
        setSubscriptionTable(subscriptionTableMasterDatabase);
      }
      else{
        subscriptionTableMasterDatabase.filter((td)=>{
          if(subscriptionType.length===0 && billingTerm.length===0 && status.length>0){
            status.map((item)=>{
              if(td.subscriptionStatus===item){
                subTableData.push(td);
              }
            })
          }
          if(subscriptionType.length===0 && billingTerm.length>0 && status.length===0){
            billingTerm.map((item)=>{
              if(td.billingTerm===item){
                subTableData.push(td);
              }
            })
          }
          if(subscriptionType.length>0 && billingTerm.length===0 && status.length===0){
            subscriptionType.map((item)=>{
              if(td.planType===item){
                subTableData.push(td);
              }
            })
          }
          if(status.length>0 && billingTerm.length>0 && subscriptionType.length>0){
            status.map((item1)=>{
              billingTerm.map((item2)=>{
                subscriptionType.map((item3)=>{
                  if(td.planType===item3 && td.billingTerm===item2 && td.subscriptionStatus===item1){
                    subTableData.push(td);
                  }
                })
              })
            })
          }
          if(status.length>0 && billingTerm.length>0 && subscriptionType.length===0){
            status.map((item1)=>{
              billingTerm.map((item2)=>{
                  if(td.billingTerm===item2 && td.subscriptionStatus===item1){
                    subTableData.push(td);
                  }
                })
            })
          }
          if(status.length>0 && billingTerm.length===0 && subscriptionType.length>0){
            status.map((item1)=>{
                subscriptionType.map((item3)=>{
                  if(td.planType===item3 && td.subscriptionStatus===item1){
                    subTableData.push(td);
                  }
                })
            })
          }
          if(status.length===0 && billingTerm.length>0 && subscriptionType.length>0){
              billingTerm.map((item2)=>{
                subscriptionType.map((item3)=>{
                  if(td.planType===item3 && td.billingTerm===item2){
                    subTableData.push(td);
                  }
                })
              })
          }
        })
        let subTableSearchData = [];
        if(searchValueTable.length>0){subTableData.map((search)=>{
          if((search.planName.toLowerCase()).includes(searchValueTable.toLowerCase())){
            subTableSearchData.push(search);
          }
        })}
        else{
          subTableSearchData=subTableData;
        }
        if((subTableSearchData.length)>0){
          setEmptyFilterSubscriptionTable(false);
          setSubscriptionTable(subTableSearchData);
          console.log(subTableSearchData);
          setIsAllLocalChangeAPIFlag(false);
        }
        else{
          console.log(subTableSearchData);
          setEmptyFilterSubscriptionTable(true);
        }
      }
 
      // let data = {
      //   "accountId": accountId,
      //   "planName": searchValueTable,
      //   "status": status,
      //   "subscriptionType": subscriptionType,
      //   "billingTerm": billingTerm,
      // }
      // await api
      //   .post(MANAGEMENT_CONSOLE_CLOUD_SERVICE_MANAGEMENT, data, config)
      //   .then((resp) => {
      //     // console.log("Main Table Data : ", resp.data);
      //     if (resp.status == 204) {
      //       setEmptyFilterSubscriptionTable(true);
      //       setIsAllLocalChangeAPIFlag(false);
      //     }
      //     else {
      //       setEmptyFilterSubscriptionTable(false);
      //       setSubscriptionTable(resp.data.subscriptions);
      //       setIsAllLocalChangeAPIFlag(false);
      //     }

      //   })
      //   .catch((error) => {
      //     // handle error
      //     // console.log(error);
      //     if (error.response.status == 401) {
      //       // console.log("Error 401");
      //       Logout();
      //     }
      //     else{
      //      setIsAllLocalChangeAPIFlag(false); 
      //     }       
      //   });
    }
  }

  // Function to add data in View Cloud Portal Details Popup
  async function GetCustomerData() {
    if (accountId) {
      // console.log("View Cloud portal details api initiated");
      await api
        .get(MANAGEMENT_CONSOLE_ACCOUNTS_URL + accountId, config)
        .then((resp) => {
          // console.log("Customer Data : ", resp.data);
          //Setting Customer Cloud Service Mangement Details
          setCustomerData(resp.data.customerCompanyName);
          setStartDate(resp.data.provisionDate);
          setPortal(resp.data.serviceUrl);
          setDomainName(resp.data.domainName);
          setInitialPassword(resp.data.password);
          setTotalLicences(resp.data.totalLicenseCount);
          setTotalAddOns(resp.data.addonsLicenseCount);
          setCloudIdentity(resp.data.vendorAccountId);
          setAdminName(resp.data.username);
          setServiceName(resp.data.serviceName);
        })
        .catch((error) => {
          // handle error
          if (error.response.status == 401) {
            // console.log("Error 401");
            Logout();
          }
        });
    }
  }

  // Function to add data in View Subscription Details Popup
  async function GetSubscriptionDetails(subscriptionid) {
    // console.log("started");
    setSubscriptionDetailIsLoadingFlag(true);
    await api
      .get(MANAGEMENT_CONSOLE_CLOUD_SERVICE_MANAGEMENT + "details/" + subscriptionid, config)
      .then((resp) => {
        // console.log("Customer Data : ", resp.data);
        setSubscriptionDetailsPlanName(resp.data.planName);
        setSubscriptionDetailsVendorSubscriptionId(
          resp.data.vendorSubscriptionId
        );
        setSubscriptionDetailsLicenceAutoRenewal(resp.data.licenseAutoRenewal);
        setSubscriptionDetailsStartDate(resp.data.subscriptionDate);
        setSubscriptionDetailsEndDate(resp.data.subscriptionEndDate);
        setSubscriptionDetailsRRP("");
        setSubscriptionDetailsIsTrial(resp.data.isTrialSub);
        setSubscriptionDetailsTrialEndDate(resp.data.trialEndDate);
        setSubscriptionDetailIsLoadingFlag(false);
      })
      .catch((error) => {
        // handle error
        if (error.response.status == 401) {
          // console.log("Error 401");
          Logout();
        }
        else{
          setSubscriptionDetailIsLoadingFlag(false);
        }  
      });
  }

  // Function to delete individual Order Summary
  const handleDelete = (id) => {
    const newOrderSummary = orderSummaryArray.filter(
      (item) => item.subId !== id
    );
    const deleteSelectedRow = selectedOrderSummaryArray.filter(
      (item) => item !== id
    )
    setSelectedOrderSummaryArray(deleteSelectedRow);
    setOrderSummaryArray(newOrderSummary);
    // console.log(newOrderSummary);
    if(newOrderSummary.length===0){
      setShowPanel(true);
    }
  };

  // Function to add total net quantity and net price in order summary component
  const addTotalNetOrderSummary = () => {
    if (orderSummaryArray) {
      orderSummaryArray.forEach((value) => {
        orderSummaryQuantityCountIntermediate =
          orderSummaryQuantityCountIntermediate + value.netQuantity;
        orderSummaryTotalPriceCountIntermediate =
          orderSummaryTotalPriceCountIntermediate + parseFloat(value.netPrice);
        // console.log("Intermediate", orderSummaryQuantityCountIntermediate);
      });
      setOrderSummaryQuantityCount(orderSummaryQuantityCountIntermediate);
      setOrderSummaryTotalPriceCount(
        orderSummaryTotalPriceCountIntermediate.toFixed(2)
      );
    }
  };

  // Function to add on Plans from clicking of expandable icon in parent row
  async function GetAddonPlans() {
    // console.log("started");
    setExpandableRowLoading(true);
    await api
      .get(MANAGEMENT_CONSOLE_SUBSCRIPTION_ADD_ONS + addOnsSubscriptionId, config)
      .then((resp) => {
        // handle success
        setAddOnsPlanArray(resp.data);
        // console.log("Customer Data : ", resp.data);
        if (resp.status == 204) {
          setAddOnPlanError(true)
        }
        setExpandableRowLoading(false);
        //Setting Customer Cloud Service Mangement Details
      })
      .catch((error) => {
        // handle error
        if (error.response.status == 401) {
          // console.log("Error 401");
          Logout();
        }
        else{
          setExpandableRowLoading(false);
        }  
      });
  }

  // Order Summary Component Show and Hide
  const showPanelDetails = () => {
    // console.log(showPanel);
    setShowPanel(!showPanel);
  };
  
  // Confirm Edit Quantity Function
  async function ConfirmEditQuantity(close) {
    setOrderSummaryProcessingButtonCheck(true);
    // console.log("started");
    let orderSummaryData = []
    orderSummaryArray.map((item) => {
      orderSummaryData.push({
        "subscriptionId": item.subId,
        "quantity": parseInt(item.updatedQuantity),
      })
    })
    let data = {
      "orderNotes": editOrderNoteConfirmation,
      "subscriptions": orderSummaryData
    }
    await api
      .post(EDIT_QUANTITY, data, config)
      .then((resp) => {
        // handle success
        // console.log("Customer Data : ", resp.data.message);
        setToastSuccessMessage(resp.data.message);
        setToastSuccess(true);
        setTimeout(() => {
          setToastSuccess(false);
          GetTableDatas();
          setOrderSummaryArray([]);
          setSelectedOrderSummaryArray([]);
          setEditOrderNoteConfirmation("");
          setShowPanel(true);
        }, 2000);
        setOrderSummaryProcessingButtonCheck(false);
        setIsProceed(false);
        close();
      })
      .catch((error) => {
        // handle error
        // console.log("Error : ", error.response.data.message);
        if (error.response.status == 401) {
          // console.log("Error 401");
          setIsProceed(false);
          Logout();
        }
        else{
          setToastErrorMessage(error.response.data.message);
        setToastError(true);
        GetTableDatas();
        setOrderSummaryProcessingButtonCheck(false);
        setTimeout(() => {
          setToastError(false);
        }, 5000);
        setIsProceed(false);
        close();
        } 
      });
  }

  // Copied to Clipboard Function
  const copyToClipboard = () => {
    navigator.clipboard.writeText(domainName);
    setCopied("Copied!");
    setTimeout(() => {
      setCopied("Click to copy");
    }, 1900);
  };

  // Sort Date Column in Subscription Table

  function SortStartDate(value) {
    let status=[];
    let subTableData=[];
    setSubscriptionTableMasterDatabase(subscriptionTableMasterDatabase.reverse());
    statusFilter.map((td)=>{
      if (statusListLocal.statusListLocal[td] === true) {
        status.push(td);
      }
    })
    let billingTerm=[]
    billingTermFilter.map((td)=>{
      if (billingTermListLocal.billingTermListLocal[td] === true) {
        billingTerm.push(td);
      }
    })
    let subscriptionType=[]
    subscriptionTypeFilter.map((td)=>{
      if (subscriptionTypeListLocal.subscriptionTypeListLocal[td] === true) {
        subscriptionType.push(td);
      }
    })
      subscriptionTableMasterDatabase.filter((td)=>{
        if(subscriptionType.length===0 && billingTerm.length===0 && status.length>0){
          status.map((item)=>{
            if(td.subscriptionStatus===item){
              subTableData.push(td);
            }
          })
        }
        if(subscriptionType.length===0 && billingTerm.length>0 && status.length===0){
          billingTerm.map((item)=>{
            if(td.billingTerm===item){
              subTableData.push(td);
            }
          })
        }
        if(subscriptionType.length>0 && billingTerm.length===0 && status.length===0){
          subscriptionType.map((item)=>{
            if(td.planType===item){
              subTableData.push(td);
            }
          })
        }
        if(status.length>0 && billingTerm.length>0 && subscriptionType.length>0){
          status.map((item1)=>{
            billingTerm.map((item2)=>{
              subscriptionType.map((item3)=>{
                if(td.planType===item3 && td.billingTerm===item2 && td.subscriptionStatus===item1){
                  subTableData.push(td);
                }
              })
            })
          })
        }
        if(status.length>0 && billingTerm.length>0 && subscriptionType.length===0){
          status.map((item1)=>{
            billingTerm.map((item2)=>{
                if(td.billingTerm===item2 && td.subscriptionStatus===item1){
                  subTableData.push(td);
                }
              })
          })
        }
        if(status.length>0 && billingTerm.length===0 && subscriptionType.length>0){
          status.map((item1)=>{
              subscriptionType.map((item3)=>{
                if(td.planType===item3 && td.subscriptionStatus===item1){
                  subTableData.push(td);
                }
              })
          })
        }
        if(status.length===0 && billingTerm.length>0 && subscriptionType.length>0){
            billingTerm.map((item2)=>{
              subscriptionType.map((item3)=>{
                if(td.planType===item3 && td.billingTerm===item2){
                  subTableData.push(td);
                }
              })
            })
        }
      })
      let subTableSearchData = [];
      if(subTableData.length>0){subTableData.map((search)=>{
        if((search.planName.toLowerCase()).includes(searchValueTable.toLowerCase())){
          subTableSearchData.push(search);
        }
      })}
      else{
        (subscriptionTableMasterDatabase).map((search)=>{
          console.log(search);
          console.log((search.planName).includes(searchValueTable));
          if((search.planName.toLowerCase()).includes(searchValueTable.toLowerCase())){
            subTableSearchData.push(search);
          }
        })
      }
      console.log(subTableSearchData);
      if((subTableSearchData.length)>0){
        console.log("Search Fxn");
        setSearchEmptySubscriptionTable(false);
        setSubscriptionTable(subTableSearchData);
        console.log(subTableSearchData.length);
      }
      else{
        console.log("Search Fxn 1");
        setSearchEmptySubscriptionTable(true);
      }
    // setSubscriptionTable(subscriptionTableMasterDatabase.reverse());
    setSortStartDateFlag(value);
  }


  // Declaring Use Effects

  useEffect(() => {
    let local = billingTermListGlobal;
    let localBillingTermBoolean = [];
    if (local.billingTermGlobal) {
      // console.log("Billing Term List Global : ", local)
      billingTermFilter.map((item) => {
        // console.log(item);
        if (billingTermListGlobal.billingTermGlobal[item] === true) {
          localBillingTermBoolean.push(true);
        }
        else {
          localBillingTermBoolean.push(false);
        }
      })
      if (localBillingTermBoolean.includes(false)) {
        setBillingTermAll(false);
      }
      else {
        setBillingTermAll(true);
      }
    }
  }, [billingTermListGlobal])

  useEffect(() => {
    let local = statusListGlobal;
    let localStatusTermBoolean = [];
    if (local.statusListGlobal) {
      // console.log("Status Term List Global : ", local)
      statusFilter.map((item) => {
        // console.log(item);
        if (statusListGlobal.statusListGlobal[item] === true) {
          localStatusTermBoolean.push(true);
        }
        else {
          localStatusTermBoolean.push(false);
        }
      })
      if (localStatusTermBoolean.includes(false)) {
        setStatusAll(false);
      }
      else {
        setStatusAll(true);
      }
    }
  }, [statusListGlobal])

  useEffect(() => {
    let local = subscriptionTypeListGlobal;
    let localSubscriptionTypeTermBoolean = [];
    if (local.subscriptionTypeListGlobal) {
      // console.log("Status Term List Global : ", local)
      subscriptionTypeFilter.map((item) => {
        // console.log(item);
        if (subscriptionTypeListGlobal.subscriptionTypeListGlobal[item] === true) {
          localSubscriptionTypeTermBoolean.push(true);
        }
        else {
          localSubscriptionTypeTermBoolean.push(false);
        }
      })
      if (localSubscriptionTypeTermBoolean.includes(false)) {
        setSubscriptionTypeAll(false);
      }
      else {
        setSubscriptionTypeAll(true);
      }
    }
  }, [subscriptionTypeListGlobal])

  useEffect(() => {
    if (subscriptionTypeAllLocal===true) {
      setSubscriptionTypeAll(!subscriptionTypeAll);
    }
    if (!globalToLocalAllItemFlag && !isAllLocalChangeAPIFlag) {
      if (subscriptionTypeAllLocal===true) {
        let StatusLocalListIntermediateState=[]
        statusFilter.map((td)=>{
          if (statusListGlobal.statusListGlobal[td] === true) {
            StatusLocalListIntermediateState.push(td);
          }
        })
        let BillingTermLocalListIntermediateState=[]
        billingTermFilter.map((td)=>{
          if (billingTermListGlobal.billingTermGlobal[td] === true) {
            BillingTermLocalListIntermediateState.push(td);
          }
        })
        GetFilterDataLocal(StatusLocalListIntermediateState, subscriptionTypeFilter, BillingTermLocalListIntermediateState);
        setFilterFlagLocalSubscriptionType(true);
        setFilterFlag(true);
        setSubscriptionTypeAll(true);
      }
      else {
        // GetFilterDataLocal([],[],[]);
        setFilterFlagLocalSubscriptionType(false);
        // setFilterFlag(false);
        setSubscriptionTypeAll(false);
      }
    }
  }, [subscriptionTypeAllLocal])

  useEffect(() => {
    if(localFilterOnChangeFlag && !isGlobalLacalChangeAPIFlag){
    let localSubscriptionTypeArray = [];
    let localSubscriptionTypeArrayBoolean = [];
    let localSubscriptionTypeArrayBooleanFalseStorage = [];
    subscriptionTypeFilter.map((item) => {
      // console.log(item);
      if (subscriptionTypeListLocal.subscriptionTypeListLocal[item] === true) {
        localSubscriptionTypeArray.push(`${item}`);
        setFilterFlagLocalSubscriptionType(true);
        setFilterFlag(true);
        localSubscriptionTypeArrayBoolean.push(true);
      }
      else {
        localSubscriptionTypeArrayBoolean.push(false);
        localSubscriptionTypeArrayBooleanFalseStorage.push(`${item}`);
      }
    })
    if (localSubscriptionTypeArrayBoolean.includes(false)) {
      setGlobalToLocalAllItemFlag(false);
      setSubscriptionTypeAllLocal(false);
    }
    else {
      setGlobalToLocalAllItemFlag(false);
      setSubscriptionTypeAllLocal(true);
    }
    if (!(localSubscriptionTypeArrayBoolean.includes(true))) {
      setFilterFlagLocalSubscriptionType(false);
      // setFilterFlag(false);
    }
    localSubscriptionTypeArray.map((item) => {
      subscriptionTypeListGlobal.subscriptionTypeListGlobal[item] = true;
    })
    localSubscriptionTypeArrayBooleanFalseStorage.map((item) => {
      subscriptionTypeListGlobal.subscriptionTypeListGlobal[item] = false;
    })
    let StatusLocalListIntermediateState=[]
    statusFilter.map((td)=>{
      if (statusListGlobal.statusListGlobal[td] === true) {
        StatusLocalListIntermediateState.push(td);
      }
    })
    let BillingTermLocalListIntermediateState=[]
    billingTermFilter.map((td)=>{
      if (billingTermListGlobal.billingTermGlobal[td] === true) {
        BillingTermLocalListIntermediateState.push(td);
      }
    })
    GetFilterDataLocal(StatusLocalListIntermediateState, localSubscriptionTypeArray, BillingTermLocalListIntermediateState);}
  }, [subscriptionTypeListLocal])

  useEffect(() => {
    if (!globalToLocalAllItemFlag) {
      setStatusAll(!statusAll);
    }
    if(localFilterOnChangeFlag && !isAllLocalChangeAPIFlag){
      if (!globalToLocalAllItemFlag) {
      if (statusAllLocal) {
        let SubscriptionLocalListIntermediateState=[]
        subscriptionTypeFilter.map((td)=>{
          if (subscriptionTypeListGlobal.subscriptionTypeListGlobal[td] === true) {
            SubscriptionLocalListIntermediateState.push(td);
          }
        })
        let BillingTermLocalListIntermediateState=[]
        billingTermFilter.map((td)=>{
          if (billingTermListGlobal.billingTermGlobal[td] === true) {
            BillingTermLocalListIntermediateState.push(td);
          }
        })
        GetFilterDataLocal(statusFilter, SubscriptionLocalListIntermediateState, BillingTermLocalListIntermediateState);
        setFilterFlagLocalStatus(true);
        setFilterFlag(true);
        // console.log("TRUE Status");
        setStatusAll(true);
      }
      else {
        // GetFilterDataLocal([],[],[]);
        setFilterFlagLocalStatus(false);
        // setFilterFlag(false);
        setStatusAll(false);
      }
    }}
  }, [statusAllLocal])

  useEffect(() => {
    if(localFilterOnChangeFlag && !isGlobalLacalChangeAPIFlag){
    let localStatusArray = [];
    let localStatusArrayBoolean = [];
    let localStatusArrayBooleanFalseStorage = [];
    statusFilter.map((item) => {
      // console.log(item);
      if (statusListLocal.statusListLocal[item] === true) {
        localStatusArray.push(`${item}`);
        setFilterFlagLocalStatus(true);
        setFilterFlag(true);
        localStatusArrayBoolean.push(true);
      }
      else {
        localStatusArrayBoolean.push(false);
        localStatusArrayBooleanFalseStorage.push(`${item}`);
      }
    })
    if (localStatusArrayBoolean.includes(false)) {
      setGlobalToLocalAllItemFlag(false);
      setStatusAllLocal(false);
    }
    else {
      setGlobalToLocalAllItemFlag(false);
      setStatusAllLocal(true);
    }
    if (!(localStatusArrayBoolean.includes(true))) {
      setFilterFlagLocalStatus(false);
      // setFilterFlag(false);
    }
    localStatusArray.map((item) => {
      statusListGlobal.statusListGlobal[item] = true;
    })
    localStatusArrayBooleanFalseStorage.map((item) => {
      statusListGlobal.statusListGlobal[item] = false;
    })
    let SubscriptionLocalListIntermediateState=[]
    subscriptionTypeFilter.map((td)=>{
      if (subscriptionTypeListGlobal.subscriptionTypeListGlobal[td] === true) {
        SubscriptionLocalListIntermediateState.push(td);
      }
    })
    let BillingTermLocalListIntermediateState=[]
    billingTermFilter.map((td)=>{
      if (billingTermListGlobal.billingTermGlobal[td] === true) {
        BillingTermLocalListIntermediateState.push(td);
      }
    })
    GetFilterDataLocal(localStatusArray, SubscriptionLocalListIntermediateState, BillingTermLocalListIntermediateState);}
  }, [statusListLocal])

  useEffect(() => {
    if (!globalToLocalAllItemFlag) {
      setBillingTermAll(!billingTermAll);
    }
    if(localFilterOnChangeFlag && !isAllLocalChangeAPIFlag){if (!globalToLocalAllItemFlag) {
      if (billingTermAllLocal) {
        let StatusLocalListIntermediateState=[]
        statusFilter.map((td)=>{
          if (statusListGlobal.statusListGlobal[td] === true) {
            StatusLocalListIntermediateState.push(td);
          }
        })
        let SubscriptionLocalListIntermediateState=[]
        subscriptionTypeFilter.map((td)=>{
          if (subscriptionTypeListGlobal.subscriptionTypeListGlobal[td] === true) {
            SubscriptionLocalListIntermediateState.push(td);
          }
        })
        GetFilterDataLocal(StatusLocalListIntermediateState, SubscriptionLocalListIntermediateState, billingTermFilter);
        setFilterFlagLocalBilling(true);
        setFilterFlag(true);
        setBillingTermAll(true);
      }
      else {
        // GetFilterDataLocal([],[],[]);
        setFilterFlagLocalBilling(false);
        // setFilterFlag(false);
        setBillingTermAll(false);
      }
    }}
  }, [billingTermAllLocal])

  useEffect(() => {
    if(localFilterOnChangeFlag && !isGlobalLacalChangeAPIFlag){
    let localBillingArray = [];
    let localBillingArrayBoolean = [];
    let localBillingArrayBooleanFalseStorage = [];
    billingTermFilter.map((item) => {
      // console.log(item);
      if (billingTermListLocal.billingTermListLocal[item] === true) {
        localBillingArray.push(`${item}`);
        setFilterFlagLocalBilling(true);
        setFilterFlag(true);
        localBillingArrayBoolean.push(true);
      }
      else {
        localBillingArrayBoolean.push(false);
        localBillingArrayBooleanFalseStorage.push(`${item}`);
      }
    })
    if (localBillingArrayBoolean.includes(false)) {
      setGlobalToLocalAllItemFlag(false);
      setBillingTermAllLocal(false);
    }
    else {
      setGlobalToLocalAllItemFlag(false);
      setBillingTermAllLocal(true);
    }
    if (!(localBillingArrayBoolean.includes(true))) {
      setFilterFlagLocalBilling(false);
      // setFilterFlag(false);
    }
    localBillingArray.map((item) => {
      billingTermListGlobal.billingTermGlobal[item] = true;
    })
    localBillingArrayBooleanFalseStorage.map((item) => {
      billingTermListGlobal.billingTermGlobal[item] = false;
    })
    let StatusLocalListIntermediateState=[]
    statusFilter.map((td)=>{
      if (statusListGlobal.statusListGlobal[td] === true) {
        StatusLocalListIntermediateState.push(td);
      }
    })
    let SubscriptionLocalListIntermediateState=[]
    subscriptionTypeFilter.map((td)=>{
      if (subscriptionTypeListGlobal.subscriptionTypeListGlobal[td] === true) {
        SubscriptionLocalListIntermediateState.push(td);
      }
    })
    GetFilterDataLocal(StatusLocalListIntermediateState, SubscriptionLocalListIntermediateState, localBillingArray);}
  }, [billingTermListLocal])

  useEffect(() => {
    if ((filterFlagLocalStatus === false && filterFlagLocalBilling === false && filterFlagLocalSubscriptionType === false)) {
      setFilterFlag(false);
    }
  }, [filterFlagLocalStatus, filterFlagLocalBilling, filterFlagLocalSubscriptionType])

  // Get Dropdown data on initial load
  useEffect(() => {
    GetTableData();
  }, []);

  // Get View cloud portal details and subscription table function on change of account Id
  useEffect(() => {
    GetCustomerData();
    GetTableDatas();
  }, [accountId]);

  // Search Functionality Function
  useEffect(() => {
    if (searchValueTable.length >= 0 && accountId) {
      let subTableData = [];
      const getData = setTimeout(() => {
        let status=[]
        statusFilter.map((td)=>{
          if (statusListLocal.statusListLocal[td] === true) {
            status.push(td);
          }
        })
        let billingTerm=[]
        billingTermFilter.map((td)=>{
          if (billingTermListLocal.billingTermListLocal[td] === true) {
            billingTerm.push(td);
          }
        })
        let subscriptionType=[]
        subscriptionTypeFilter.map((td)=>{
          if (subscriptionTypeListLocal.subscriptionTypeListLocal[td] === true) {
            subscriptionType.push(td);
          }
        })
          subscriptionTableMasterDatabase.filter((td)=>{
            if(subscriptionType.length===0 && billingTerm.length===0 && status.length>0){
              status.map((item)=>{
                if(td.subscriptionStatus===item){
                  subTableData.push(td);
                }
              })
            }
            if(subscriptionType.length===0 && billingTerm.length>0 && status.length===0){
              billingTerm.map((item)=>{
                if(td.billingTerm===item){
                  subTableData.push(td);
                }
              })
            }
            if(subscriptionType.length>0 && billingTerm.length===0 && status.length===0){
              subscriptionType.map((item)=>{
                if(td.planType===item){
                  subTableData.push(td);
                }
              })
            }
            if(status.length>0 && billingTerm.length>0 && subscriptionType.length>0){
              status.map((item1)=>{
                billingTerm.map((item2)=>{
                  subscriptionType.map((item3)=>{
                    if(td.planType===item3 && td.billingTerm===item2 && td.subscriptionStatus===item1){
                      subTableData.push(td);
                    }
                  })
                })
              })
            }
            if(status.length>0 && billingTerm.length>0 && subscriptionType.length===0){
              status.map((item1)=>{
                billingTerm.map((item2)=>{
                    if(td.billingTerm===item2 && td.subscriptionStatus===item1){
                      subTableData.push(td);
                    }
                  })
              })
            }
            if(status.length>0 && billingTerm.length===0 && subscriptionType.length>0){
              status.map((item1)=>{
                  subscriptionType.map((item3)=>{
                    if(td.planType===item3 && td.subscriptionStatus===item1){
                      subTableData.push(td);
                    }
                  })
              })
            }
            if(status.length===0 && billingTerm.length>0 && subscriptionType.length>0){
                billingTerm.map((item2)=>{
                  subscriptionType.map((item3)=>{
                    if(td.planType===item3 && td.billingTerm===item2){
                      subTableData.push(td);
                    }
                  })
                })
            }
          })
          let subTableSearchData = [];
          if(subTableData.length>0){subTableData.map((search)=>{
            if((search.planName.toLowerCase()).includes(searchValueTable.toLowerCase())){
              subTableSearchData.push(search);
            }
          })}
          else{
            subscriptionTableMasterDatabase.map((search)=>{
              console.log(search);
              console.log((search.planName).includes(searchValueTable));
              if((search.planName.toLowerCase()).includes(searchValueTable.toLowerCase())){
                subTableSearchData.push(search);
              }
            })
          }
          console.log(subTableSearchData);
          if((subTableSearchData.length)>0){
            console.log("Search Fxn");
            setSearchEmptySubscriptionTable(false);
            setSubscriptionTable(subTableSearchData);
            console.log(subTableSearchData.length);
          }
          else{
            console.log("Search Fxn 1");
            setSearchEmptySubscriptionTable(true);
          }
        // let data = {
        //   "accountId": accountId,
        //   "planName": searchValueTable,
        //   "status": StatusLocalListIntermediateState,
        //   "subscriptionType": SubscriptionLocalListIntermediateState,
        //   "billingTerm": BillingTermLocalListIntermediateState,
        // }
        // api.post(MANAGEMENT_CONSOLE_CLOUD_SERVICE_MANAGEMENT, data, config)
        //   .then((resp) => {
        //     // console.log("Main Table Data : ", resp.data);
        //     if (resp.status == 204) {
        //       setSearchEmptySubscriptionTable(true);
        //       setSubscriptionTable(resp.data.subscriptions);
        //     }
        //     else {
        //       setSearchEmptySubscriptionTable(false);
        //       setSubscriptionTable(resp.data.subscriptions);
        //     }
        //   })
        //   .catch((error) => {
        //     // handle error
        //     // console.log(error);
        //   });
      }, 500);
      return () => clearTimeout(getData);
    }
  }, [searchValueTable]);

  //Refresh Subsription table when Pending status is converted to active one 
  useEffect(() => {
    // console.log("Refresh Variable : ", refreshPageIndividualUpdate);
  if(refreshPageIndividualUpdate>0){
    let StatusLocalListIntermediateState=[]
        statusFilter.map((td)=>{
          if (statusListLocal.statusListLocal[td] === true) {
            StatusLocalListIntermediateState.push(td);
          }
        })
        let BillingTermLocalListIntermediateState=[]
        billingTermFilter.map((td)=>{
          if (billingTermListLocal.billingTermListLocal[td] === true) {
            BillingTermLocalListIntermediateState.push(td);
          }
        })
        let SubscriptionLocalListIntermediateState=[]
        subscriptionTypeFilter.map((td)=>{
          if (subscriptionTypeListLocal.subscriptionTypeListLocal[td] === true) {
            SubscriptionLocalListIntermediateState.push(td);
          }
        })
        let data = {
          "accountId": accountId,
          "planName": searchValueTable,
          "status": StatusLocalListIntermediateState,
          "subscriptionType": SubscriptionLocalListIntermediateState,
          "billingTerm": BillingTermLocalListIntermediateState,
        }
        api.get(MANAGEMENT_CONSOLE_CLOUD_SERVICE_MANAGEMENT+accountId, config)
        .then((resp) => {
          console.log("Active Account change");
          if (resp.status == 204) {
            setSearchEmptySubscriptionTable(true);
            setSubscriptionTable(resp.data.subscriptions);
          }
          else {
            setSearchEmptySubscriptionTable(false);
            setSubscriptionTable(resp.data.subscriptions);
          }
        })
        .catch((error) => {
          // handle error
          // console.log(error);
        });
  }
  }, [refreshPageIndividualUpdate])

  // Intermediate order summary change
  useEffect(() => {
    // console.log(
    //   "intermediateorderSummaryArray : ",
    //   intermediateorderSummaryArray
    // );
    let checkFlag = false;
    if (orderSummaryArray.length > 0) {
      orderSummaryArray.map((item) => {
        if (item.subId === intermediateorderSummaryArray.subId) {
          item.netPrice = intermediateorderSummaryArray.netPrice;
          item.netQuantity = intermediateorderSummaryArray.netQuantity;
          item.updatedPrice = intermediateorderSummaryArray.updatedPrice;
          item.updatedQuantity = intermediateorderSummaryArray.updatedQuantity;
          checkFlag = true;
          addTotalNetOrderSummary();
          if (orderSummaryEditIntermediateFlag) {
            setOrderSummaryEditFinalFlag(true);
          }
        }
      });
    }
    if (checkFlag == false && intermediateorderSummaryArray) {
      setOrderSummaryArray((orderSummaryArray) => [
        ...orderSummaryArray,
        intermediateorderSummaryArray,
      ]);
    }
  }, [intermediateorderSummaryArray]);

  // Order summary change
  useEffect(() => {
    // console.log("OrderSummaryArray : ", orderSummaryArray);
    orderSummaryArray.map((item) => {
      if (!(selectedOrderSummaryArray.includes(item.subId))) {
        setSelectedOrderSummaryArray((prev) => [...prev, item.subId]);
      }
    })
    addTotalNetOrderSummary();
  }, [orderSummaryArray]);

  useEffect(() => {
    // console.log("selectedOrderSummaryArray : ", selectedOrderSummaryArray)
  }, [selectedOrderSummaryArray]);

  useEffect(() => {
    // console.log("OrderSummaryArray : ", orderSummaryArray);
    addTotalNetOrderSummary();
    if (orderSummaryEditIntermediateFlag) {
      setOrderSummaryEditFinalFlag(false);
      setOrderSummaryEditIntermediateFlag(false);
    }
  }, [orderSummaryEditFinalFlag]);

  // Gets Addons Plan API
  useEffect(() => {
    if (addOnsSubscriptionId) {
      GetAddonPlans();
    }
  }, [addOnsSubscriptionId]);
  
  // Refresh the duplicate data api when we have a pending status in subscription table
  useEffect(() => {
    if(isPendingRefreshFlag){
      // console.log("Inside the condition")
      setTimeout(() => {
        setIsPendingRefreshFlag(false);
        GetTableRefreshOrder();
      }, 5000);
    }
  
  }, [isPendingRefreshFlag]);

  return (
    <div className="customer-management-console-main-div mb-5">
      <div className={`edit-quantity-toast-alert ${toastSuccess ? "active" : ""}`}>
        <Alert variant="success">
          <div className='d-flex align-items-center'>
            <Check2Circle size={24} color="#1D874C" />
            <span className="mx-3 text-medium">
              {toastSuccessMessage}
            </span>
          </div>
        </Alert>
      </div>
      <div className={`edit-quantity-toast-alert ${toastError ? "active" : ""}`}>
        <Alert variant="danger">
          <div className='d-flex align-items-center'>
            <XCircle size={24} color="#9C3B35" />
            <span className="mx-3 text-medium">
              {toastErrorMessage}
            </span>
          </div>
        </Alert>
      </div>
      <h1 className="d-block d-md-none mobile-h1">Management Console</h1>
      <Container fluid>
        <Row>
          <Col md={5} className="mb-2">
            <div className="d-md-table d-block py-2">
              <h2 className="d-md-table-cell d-block mobile-my-cloud-service-heading cloud-services-heading-font-size">My Cloud Services</h2>
              <Col className="mx-md-3 col-sm-12 col-xs-12">
                {!emptyDropdownMyCloudSrvicesflag && <NavDropdown title={serviceName} id="sevices-nav-dropdown" className="servcies-dropdown"
                >
                  {dropdownData.map((arrayItems) => {
                    if (arrayItems.accountId === accountId) {
                      return (
                        <NavDropdown.Item
                          id={arrayItems.integrationCode}
                          value={arrayItems.accountId}
                          className={serviceName === arrayItems.serviceName ? "selected" : ""}
                          onClick={(e) => {
                            setIntegrationCode(e.target.id);
                            setAccountId(e.target.getAttribute("value"));

                          }}
                        >
                          {arrayItems.serviceName}
                        </NavDropdown.Item>
                      );
                    } else {
                      return (
                        <NavDropdown.Item
                          id={arrayItems.integrationCode}
                          value={arrayItems.accountId}
                          className={serviceName === arrayItems.serviceName ? "selected" : ""}
                          onClick={(e) => {
                            setIntegrationCode(e.target.id);
                            // console.log("asdfasd", e.target.getAttribute(
                            //   "value"
                            // ));
                            setAccountId(e.target.getAttribute("value"));
                            setOrderSummaryArray([]);     // Empty the Order Summary Box on My Cloud Services Dropdown
                            setSelectedOrderSummaryArray([]);
                            setShowPanel(true);           // Close the Order Summary Box
                          }}
                        >
                          {arrayItems.serviceName}
                        </NavDropdown.Item>
                      );
                    }
                  })}
                </NavDropdown>
                }
                {emptyDropdownMyCloudSrvicesflag && <NavDropdown title="No Cloud Service" id="sevices-nav-dropdown" className="servcies-dropdown"
                >
                  <NavDropdown.Item
                    id="NoCloudServicesAvailable"
                    value="No Cloud Services Available"
                  >
                    No Cloud Services Available
                  </NavDropdown.Item>

                </NavDropdown>
                }
              </Col>
            </div>
          </Col>
          <Col md={7} className="mb-2">
            <div className="d-flex justify-content-between justify-content-md-end align-items-center h-100">
              <label className="pt-0 label-sm">
                Total Licenses
                <span className="circle-bg label-sm">
                  {!emptyDropdownMyCloudSrvicesflag ? <strong className="label-sm"> {totalLicences}</strong> : <strong className="label-sm"> 0 </strong>}
                </span>
              </label>
              <label className="pt-0 mx-md-3 label-sm label-sm">
                Total Add-Ons
                <span className="circle-bg">
                  {!emptyDropdownMyCloudSrvicesflag ? <strong className="label-sm"> {totalAddOns}</strong> : <strong className="label-sm"> 0 </strong>}
                </span>
              </label>
              <label className="pt-0 mx-md-1 seperator">&nbsp;</label>
              <div className="pt-0 my-2 ml-3">
                {emptyDropdownMyCloudSrvicesflag &&
                  <>
                    <a className="cloud-portal">
                      <span className="d-none d-md-block text-disabled">View Cloud Portal Details</span>
                      <span className="d-block d-md-none text-disabled">View Details</span>
                    </a>
                  </>
                }
                {integrationCode === "microsoftsaas" && (
                  <CSPViewCloudPortalDetails
                    serviceName={serviceName}
                    customerData={customerData}
                    copyToClipboard={copyToClipboard}
                    copied={copied}
                    setActiveLocalKey={props.setActiveLocalKey}
                    startDate={startDate}
                    domainName={domainName}
                    portal={portal}
                    passwordType={passwordType}
                    initialPassword={initialPassword}
                    setPasswordType={setPasswordType}
                  />
                )}
                {integrationCode === "googleworkspace" && (
                  <GWSViewCloudPortalDetails
                  serviceName={serviceName}
                  customerData={customerData}
                  cloudIdentity={cloudIdentity}
                  startDate={startDate}
                  domainName={domainName}
                  copyToClipboard={copyToClipboard}
                  copied={copied}
                  setActiveLocalKey={props.setActiveLocalKey}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {(showTermsAndConditionFlag != sessionStorage.getItem("Accepted_Version")) && <TermsAndConditions />}
      <div className="mgmt-console-table-row">
        <div
          className="my-3 d-flex"
          style={{
            overflowX: "hidden",
            position: "relative",
          }}
        >
          <Col
            className="subscription-table py-0 pr-0"
            style={{
              overflowX: !showPanel ? "hidden" : "",
              width: !showPanel ? "calc(100% - 520px)" : "calc(100% - 35px)",
              display: !showPanel ? "flex" : "block",
              flexDirection: "column",
              flex: "0 0 auto",
              transition: "all 0.3s",
            }}
          >
            <div className="subscription-table-inner">
              <Row className="my-0 table-heading align-items-center">
                <Col className={`pl-1 ${searchIconFlag ? "hide-in-mobile" : ""}`}>
                  <h5 className="py-3 mb-0 mobile-padding-search-open subscription-heading-table-header">Subscriptions</h5>
                </Col>
                <Col className="px-1">
                  <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                    <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                      {!searchIconFlag && !subscriptionTable &&
                        <span className="mx-3">&nbsp;</span>
                      }
                      {!searchIconFlag && subscriptionTable &&
                        <span className="search mx-3 cpointer" onClick={() => {setSearchIconFlag(!searchIconFlag);}}>&nbsp;</span>
                      }
                      {searchIconFlag &&
                        <InputGroup className="search-input-box">
                          <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                            <span className="searchgrey cpointer">
                              &nbsp;
                            </span>
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Enter Plan Name"
                            aria-label="Enter Plan Name"
                            aria-describedby="basic-addon2"
                            className="search-input-box"
                            value={searchValueTable}
                            onChange={(e) => setSearchValueTable(e.target.value)}
                          />
                          <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close" 
                          onClick={() => {
                            setSearchValueTable("");
                            setTimeout(() => {
                              setSearchIconFlag(!searchIconFlag);
                            }, 500);
                            }}>
                            <span className="closebtn" >
                              &nbsp;
                            </span>
                          </InputGroup.Text>
                        </InputGroup>}
                      {subscriptionTable && !filterFlag && <span className="filter-black cpointer" id="filterblock" onClick={() => { 
                        setShowFilterBox(!showFilterBox);
                        if(showFilterBox){setIsGlobalLacalChangeAPIFlag(true);ClearGolbalFunction(); } 
                        }}></span>}
                      {subscriptionTable && filterFlag && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {showFilterBox &&
                  <GlobalFilterBox
                    setShowFilterBox={setShowFilterBox}
                    billingTermAll={billingTermAll}
                    billingTermListGlobal={billingTermListGlobal}
                    setBillingTermListGlobal={setBillingTermListGlobal}
                    subscriptionTypeAll={subscriptionTypeAll}
                    billingTermFilter={billingTermFilter}
                    setBillingTermAll={setBillingTermAll}
                    statusAll={statusAll}
                    setStatusAll={setStatusAll}
                    setSubscriptionTypeListGlobal={setSubscriptionTypeListGlobal}
                    subscriptionTypeListGlobal={subscriptionTypeListGlobal}
                    setStatusListGlobal={setStatusListGlobal}
                    statusFilter={statusFilter}
                    statusListGlobal={statusListGlobal}
                    billingTermListLocal={billingTermListLocal}
                    setGlobalToLocalAllItemFlag={setGlobalToLocalAllItemFlag}
                    setStatusAllLocal={setStatusAllLocal}
                    subscriptionTypeFilter={subscriptionTypeFilter}
                    setSubscriptionTypeAll={setSubscriptionTypeAll}
                    setSubscriptionTypeAllLocal={setSubscriptionTypeAllLocal}
                    setFilterFlagLocalSubscriptionType={setFilterFlagLocalSubscriptionType}
                    filterFlag={filterFlag}
                    setBillingTermAllLocal={setBillingTermAllLocal}
                    subscriptionTypeListLocal={subscriptionTypeListLocal}
                    statusListLocal={statusListLocal}
                    setFilterFlagLocalBilling={setFilterFlagLocalBilling}
                    setFilterFlagLocalStatus={setFilterFlagLocalStatus}
                    GetTableDatas={GetTableDatas}
                    GetFilterData={GetFilterData}
                    setIsGlobalLacalChangeAPIFlag={setIsGlobalLacalChangeAPIFlag}
                    ClearGolbalFunction={ClearGolbalFunction}
                  />
                }
              <div className="management-console-table">
                <div className={(!emptyDropdownMyCloudSrvicesflag && !emptySubscriptionTable && !subscriptionTableTryAgain && !emptyFilterSubscriptionTable && !searchEmptySubscriptionTable) ? "double-scrollbar-tablet":""} style={{ width: !showPanel ? "100%" : "100%", overflowX: "auto", }}>
                  {!subscriptionTable && emptyDropdownMyCloudSrvicesflag && (
                    <EmptyCloudServices 
                      EmptyCloudServicesIllustration={EmptyCloudServicesIllustration}
                      setActiveLocalKey={props.setActiveLocalKey} 
                    />
                  )
                  }
                  {emptySubscriptionTable && dropdownData && (
                    <EmptySubscription
                      EmptySubscriptionTableIllustration={EmptySubscriptionTableIllustration}
                      setActiveLocalKey={props.setActiveLocalKey}
                    /> 
                  )
                  }
                  {subscriptionTableTryAgain && dropdownData && (
                    <SubscriptionTableTryAgain
                      EmptySubscriptionTableIllustration={EmptySubscriptionTableIllustration}
                      setActiveLocalKey={props.setActiveLocalKey}
                    /> 
                  )
                  }
                  
                  {integrationCode && searchEmptySubscriptionTable && (
                    <NoPlanName
                      ColumnNameMicrosoftCSP={ColumnNameMicrosoftCSP}
                      showPriceFlag={showPriceFlag}
                      EmptyDataTableFilterSearch={EmptyDataTableFilterSearch}
                    />
                  )}
                  {integrationCode && emptyFilterSubscriptionTable && (
                    <NoData
                      ColumnNameMicrosoftCSP={ColumnNameMicrosoftCSP}
                      showPriceFlag={showPriceFlag}
                      EmptyDataTableFilterSearch={EmptyDataTableFilterSearch}
                    />
                  )}
                  {integrationCode && subscriptionTable && !emptyFilterSubscriptionTable && !searchEmptySubscriptionTable && (
                    <div className="table-body-height-responsive">
                      <div style={{ width: !showPanel ? "130%" : "100%" }}>
                      <SubscriptionTable 
                        ColumnNameMicrosoftCSP={ColumnNameMicrosoftCSP}
                        showPriceFlag={showPriceFlag}
                        showRRPFlag={showRRPFlag}
                        updatedQuantityIntermediate={updatedQuantityIntermediate}
                        isSubscriptionDetailsPopupOpen={isSubscriptionDetailsPopupOpen}
                        setIsSubscriptionDetailsPopupOpen={setIsSubscriptionDetailsPopupOpen}
                        integrationCode={integrationCode}
                        subscriptionDetailIsLoadingFlag={subscriptionDetailIsLoadingFlag}
                        subscriptionDetailsIsTrial={subscriptionDetailsIsTrial}
                        setUpdatedQuantityIntermediateErrorState={setUpdatedQuantityIntermediateErrorState}
                        subscriptionDetailsPlanName={subscriptionDetailsPlanName}
                        subscriptionDetailsVendorSubscriptionId={subscriptionDetailsVendorSubscriptionId}
                        subscriptionDetailsLicenceAutoRenewal={subscriptionDetailsLicenceAutoRenewal}
                        subscriptionDetailsStartDate={subscriptionDetailsStartDate}
                        subscriptionDetailsEndDate={subscriptionDetailsEndDate}
                        expendableRowOpen={expendableRowOpen}
                        setExpendableRowOpen={setExpendableRowOpen}
                        subscriptionId={subscriptionId}
                        updatedQuantityIntermediateErrorState={updatedQuantityIntermediateErrorState}
                        addOnsPlanArray={addOnsPlanArray}
                        subscriptionDetailsTrialEndDate={subscriptionDetailsTrialEndDate}
                        subscriptionTable={subscriptionTable}
                        selectedOrderSummaryArray={selectedOrderSummaryArray}
                        setUpdatedQuantityIntermediate={setUpdatedQuantityIntermediate}
                        setAddOnsSubscriptionId={setAddOnsSubscriptionId}
                        setParentAddOns={setParentAddOns}
                        setSubscriptionId={setSubscriptionId}
                        setIntermediateOrderSummaryArray={setIntermediateOrderSummaryArray}
                        setShowPanel={setShowPanel}
                        GetSubscriptionDetails={GetSubscriptionDetails}
                        AddOnPlanError={AddOnPlanError}
                        expandableRowLoading={expandableRowLoading}
                        parentAddOns={parentAddOns}
                        setActiveLocalKey={props.setActiveLocalKey}
                        filterFlagLocalSubscriptionType={filterFlagLocalSubscriptionType}
                        subscriptionTypeAllLocal={subscriptionTypeAllLocal}
                        setLocalFilterOnChangeFlag={setLocalFilterOnChangeFlag}
                        setSubscriptionTypeListLocal={setSubscriptionTypeListLocal}
                        subscriptionTypeFilter={subscriptionTypeFilter}
                        setGlobalToLocalAllItemFlag={setGlobalToLocalAllItemFlag}
                        setSubscriptionTypeAllLocal={setSubscriptionTypeAllLocal}
                        subscriptionTypeListLocal={subscriptionTypeListLocal}
                        filterFlagLocalBilling={filterFlagLocalBilling}
                        statusAllLocal={statusAllLocal}
                        billingTermAllLocal={billingTermAllLocal}
                        setBillingTermListLocal={setBillingTermListLocal}
                        billingTermFilter={billingTermFilter}
                        setBillingTermAllLocal={setBillingTermAllLocal}
                        statusFilter={statusFilter}
                        setStatusListLocal={setStatusListLocal}
                        billingTermListLocal={billingTermListLocal}
                        filterFlagLocalStatus={filterFlagLocalStatus}
                        setStatusAllLocal={setStatusAllLocal}
                        statusListLocal={statusListLocal}
                        setIsAllLocalChangeAPIFlag={setIsAllLocalChangeAPIFlag}
                        SortStartDate={SortStartDate}
                        sortStartDateFlag={sortStartDateFlag}
                      />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <OrderSummary
            showPanel={showPanel}
            showPriceFlag={showPriceFlag}
            emptyDropdownMyCloudSrvicesflag={emptyDropdownMyCloudSrvicesflag}
            updatedQuantityIntermediate={updatedQuantityIntermediate}
            emptySubscriptionTable={emptySubscriptionTable}
            showPanelDetails={showPanelDetails}
            orderSummaryArray={orderSummaryArray}
            EmptyOrderSummaryIllustration={EmptyOrderSummaryIllustration}
            setUpdatedQuantityIntermediateErrorState={setUpdatedQuantityIntermediateErrorState}
            setUpdatedQuantityIntermediate={setUpdatedQuantityIntermediate}
            updatedQuantityIntermediateErrorState={updatedQuantityIntermediateErrorState}
            orderSummaryQuantityCount={orderSummaryQuantityCount}
            setShowPanel={setShowPanel}
            handleDelete={handleDelete}
            setIntermediateOrderSummaryArray={setIntermediateOrderSummaryArray}
            setOrderSummaryEditIntermediateFlag={setOrderSummaryEditIntermediateFlag}
            setSelectedOrderSummaryArray={setSelectedOrderSummaryArray}
            ConfirmEditQuantity={ConfirmEditQuantity}
            setEditOrderNoteConfirmation={setEditOrderNoteConfirmation}
            orderSummaryTotalPriceCount={orderSummaryTotalPriceCount}
            setOrderSummaryArray={setOrderSummaryArray}
            editOrderNoteConfirmation={editOrderNoteConfirmation}
            orderSummaryProcessingButtonCheck={orderSummaryProcessingButtonCheck}
            setOrderSummaryProcessingButtonCheck={setOrderSummaryProcessingButtonCheck}
            isProceed={isProceed}
            setIsProceed={setIsProceed}
          /> 
        </div>
      </div>
    </div>
  );
};
export default ManagementConsoleCustomer;